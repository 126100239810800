/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PaymentInfo } from '../models/payment-info';
import { PaymentWallet } from '../models/payment-wallet';
import { PaymentStatus } from '../models/payment-status';
import { PaymentInitData } from '../models/payment-init-data';
import { PaymentCardManageResult } from '../models/payment-card-manage-result';
import { PaymentCardManage } from '../models/payment-card-manage';
@Injectable({
  providedIn: 'root'
})
class PaymentService extends __BaseService {
  static readonly incustControllersClientClientPaymentInfoByTerminalPath =
    '/pmt/info/terminal/{terminal_id}';
  static readonly incustControllersClientClientPaymentInfoByTransactionPath =
    '/pmt/info/transaction/{transaction_id}';
  static readonly incustControllersClientClientPaymentInfoPath =
    '/pmt/info/{payment_id}';
  static readonly incustControllersClientClientPaymentsByTransactionsPath =
    '/pmt/payments/transaction/{transaction_id}';
  static readonly incustControllersClientClientPaymentWalletPath =
    '/pmt/wallet';
  static readonly incustControllersClientClientPaymentWalletCardPasswordPath =
    '/pmt/wallet/cardpassword/{action}';
  static readonly incustControllersClientClientPaymentWalletPaymentPath =
    '/pmt/wallet/payment';
  static readonly incustControllersClientClientPaymentWalletPaymentOtpPath =
    '/pmt/wallet/payment/otp';
  static readonly incustControllersClientClientPaymentWalletPaymentStatusPath =
    '/pmt/wallet/payment/status';
  static readonly incustControllersClientClientPaymentWalletPaymentExPath =
    '/pmt/wallet/payment/{referrer}';
  static readonly incustControllersClientClientPaymentWalletByTypePath =
    '/pmt/wallet/{wallet_type}';
  static readonly incustControllersClientClientPaymentWalletManageWDataPath =
    '/pmt/wallet/{wallet_type}/{action}';
  static readonly incustControllersClientClientPaymentWalletManageWDataExPath =
    '/pmt/wallet/{wallet_type}/{action}/{referrer}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns information about a mobile-app type payment that needs to be processed, by terminal
   * @param params The `PaymentService.IncustControllersClientClientPaymentInfoByTerminalParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `transaction_info`: Include transaction info
   *
   * - `goods_item_info`: Include goods item info
   *
   * @return Payment info
   */
  incustControllersClientClientPaymentInfoByTerminalResponse(
    params: PaymentService.IncustControllersClientClientPaymentInfoByTerminalParams
  ): __Observable<__StrictHttpResponse<PaymentInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.transactionInfo != null)
      __params = __params.set(
        'transaction_info',
        params.transactionInfo.toString()
      );
    if (params.goodsItemInfo != null)
      __params = __params.set(
        'goods_item_info',
        params.goodsItemInfo.toString()
      );
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/pmt/info/terminal/${encodeURIComponent(String(params.terminalId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentInfo>;
      })
    );
  }
  /**
   * Returns information about a mobile-app type payment that needs to be processed, by terminal
   * @param params The `PaymentService.IncustControllersClientClientPaymentInfoByTerminalParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `transaction_info`: Include transaction info
   *
   * - `goods_item_info`: Include goods item info
   *
   * @return Payment info
   */
  incustControllersClientClientPaymentInfoByTerminal(
    params: PaymentService.IncustControllersClientClientPaymentInfoByTerminalParams
  ): __Observable<PaymentInfo> {
    return this.incustControllersClientClientPaymentInfoByTerminalResponse(
      params
    ).pipe(__map((_r) => _r.body as PaymentInfo));
  }

  /**
   * Returns information about a mobile-app type payment that needs to be processed, by transaction
   * @param params The `PaymentService.IncustControllersClientClientPaymentInfoByTransactionParams` containing the following parameters:
   *
   * - `transaction_id`: Transaction ID
   *
   * - `transaction_info`: Include transaction info
   *
   * - `goods_item_info`: Include goods item info
   *
   * @return Payment info
   */
  incustControllersClientClientPaymentInfoByTransactionResponse(
    params: PaymentService.IncustControllersClientClientPaymentInfoByTransactionParams
  ): __Observable<__StrictHttpResponse<PaymentInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.transactionInfo != null)
      __params = __params.set(
        'transaction_info',
        params.transactionInfo.toString()
      );
    if (params.goodsItemInfo != null)
      __params = __params.set(
        'goods_item_info',
        params.goodsItemInfo.toString()
      );
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/pmt/info/transaction/${encodeURIComponent(
          String(params.transactionId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentInfo>;
      })
    );
  }
  /**
   * Returns information about a mobile-app type payment that needs to be processed, by transaction
   * @param params The `PaymentService.IncustControllersClientClientPaymentInfoByTransactionParams` containing the following parameters:
   *
   * - `transaction_id`: Transaction ID
   *
   * - `transaction_info`: Include transaction info
   *
   * - `goods_item_info`: Include goods item info
   *
   * @return Payment info
   */
  incustControllersClientClientPaymentInfoByTransaction(
    params: PaymentService.IncustControllersClientClientPaymentInfoByTransactionParams
  ): __Observable<PaymentInfo> {
    return this.incustControllersClientClientPaymentInfoByTransactionResponse(
      params
    ).pipe(__map((_r) => _r.body as PaymentInfo));
  }

  /**
   * Returns payment info
   * @param params The `PaymentService.IncustControllersClientClientPaymentInfoParams` containing the following parameters:
   *
   * - `payment_id`: Payment ID
   *
   * - `transaction_info`: Include transaction info
   *
   * - `goods_item_info`: Include goods item info
   *
   * @return Payment info
   */
  incustControllersClientClientPaymentInfoResponse(
    params: PaymentService.IncustControllersClientClientPaymentInfoParams
  ): __Observable<__StrictHttpResponse<PaymentInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.transactionInfo != null)
      __params = __params.set(
        'transaction_info',
        params.transactionInfo.toString()
      );
    if (params.goodsItemInfo != null)
      __params = __params.set(
        'goods_item_info',
        params.goodsItemInfo.toString()
      );
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/pmt/info/${encodeURIComponent(String(params.paymentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentInfo>;
      })
    );
  }
  /**
   * Returns payment info
   * @param params The `PaymentService.IncustControllersClientClientPaymentInfoParams` containing the following parameters:
   *
   * - `payment_id`: Payment ID
   *
   * - `transaction_info`: Include transaction info
   *
   * - `goods_item_info`: Include goods item info
   *
   * @return Payment info
   */
  incustControllersClientClientPaymentInfo(
    params: PaymentService.IncustControllersClientClientPaymentInfoParams
  ): __Observable<PaymentInfo> {
    return this.incustControllersClientClientPaymentInfoResponse(params).pipe(
      __map((_r) => _r.body as PaymentInfo)
    );
  }

  /**
   * Returns information about all payments by transaction
   * @param params The `PaymentService.IncustControllersClientClientPaymentsByTransactionsParams` containing the following parameters:
   *
   * - `transaction_id`: Transaction ID
   *
   * - `transaction_info`: Include transaction info
   *
   * - `goods_item_info`: Include goods item info
   *
   * @return Payment info
   */
  incustControllersClientClientPaymentsByTransactionsResponse(
    params: PaymentService.IncustControllersClientClientPaymentsByTransactionsParams
  ): __Observable<__StrictHttpResponse<Array<PaymentInfo>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.transactionInfo != null)
      __params = __params.set(
        'transaction_info',
        params.transactionInfo.toString()
      );
    if (params.goodsItemInfo != null)
      __params = __params.set(
        'goods_item_info',
        params.goodsItemInfo.toString()
      );
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/pmt/payments/transaction/${encodeURIComponent(
          String(params.transactionId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PaymentInfo>>;
      })
    );
  }
  /**
   * Returns information about all payments by transaction
   * @param params The `PaymentService.IncustControllersClientClientPaymentsByTransactionsParams` containing the following parameters:
   *
   * - `transaction_id`: Transaction ID
   *
   * - `transaction_info`: Include transaction info
   *
   * - `goods_item_info`: Include goods item info
   *
   * @return Payment info
   */
  incustControllersClientClientPaymentsByTransactions(
    params: PaymentService.IncustControllersClientClientPaymentsByTransactionsParams
  ): __Observable<Array<PaymentInfo>> {
    return this.incustControllersClientClientPaymentsByTransactionsResponse(
      params
    ).pipe(__map((_r) => _r.body as Array<PaymentInfo>));
  }

  /**
   * Returns mobile payment wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletParams` containing the following parameters:
   *
   * - `terminal_id`: Unique identifier of Terminal
   *
   * - `pos_id`: Unique identifier of POS
   *
   * - `loyalty_id`: Unique identifier of loyalty
   *
   * - `currency`: Currency
   *
   * @return Mobile payment wallets
   */
  incustControllersClientClientPaymentWalletResponse(
    params: PaymentService.IncustControllersClientClientPaymentWalletParams
  ): __Observable<__StrictHttpResponse<Array<PaymentWallet>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.terminalId != null)
      __params = __params.set('terminal_id', params.terminalId.toString());
    if (params.posId != null)
      __params = __params.set('pos_id', params.posId.toString());
    if (params.loyaltyId != null)
      __params = __params.set('loyalty_id', params.loyaltyId.toString());
    if (params.currency != null)
      __params = __params.set('currency', params.currency.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pmt/wallet`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<PaymentWallet>>;
      })
    );
  }
  /**
   * Returns mobile payment wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletParams` containing the following parameters:
   *
   * - `terminal_id`: Unique identifier of Terminal
   *
   * - `pos_id`: Unique identifier of POS
   *
   * - `loyalty_id`: Unique identifier of loyalty
   *
   * - `currency`: Currency
   *
   * @return Mobile payment wallets
   */
  incustControllersClientClientPaymentWallet(
    params: PaymentService.IncustControllersClientClientPaymentWalletParams
  ): __Observable<Array<PaymentWallet>> {
    return this.incustControllersClientClientPaymentWalletResponse(params).pipe(
      __map((_r) => _r.body as Array<PaymentWallet>)
    );
  }

  /**
   * Сard password managements for payment wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletCardPasswordParams` containing the following parameters:
   *
   * - `data`: Operation data
   *
   * - `action`: Action type
   *
   * @return Response status
   */
  incustControllersClientClientPaymentWalletCardPasswordResponse(
    params: PaymentService.IncustControllersClientClientPaymentWalletCardPasswordParams
  ): __Observable<__StrictHttpResponse<{ status?: 'success' | 'fail' }>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/pmt/wallet/cardpassword/${encodeURIComponent(String(params.action))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{ status?: 'success' | 'fail' }>;
      })
    );
  }
  /**
   * Сard password managements for payment wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletCardPasswordParams` containing the following parameters:
   *
   * - `data`: Operation data
   *
   * - `action`: Action type
   *
   * @return Response status
   */
  incustControllersClientClientPaymentWalletCardPassword(
    params: PaymentService.IncustControllersClientClientPaymentWalletCardPasswordParams
  ): __Observable<{ status?: 'success' | 'fail' }> {
    return this.incustControllersClientClientPaymentWalletCardPasswordResponse(
      params
    ).pipe(__map((_r) => _r.body as { status?: 'success' | 'fail' }));
  }

  /**
   * Payment from wallet
   * @param data Operation data
   * @return Response status
   */
  incustControllersClientClientPaymentWalletPaymentResponse(
    data: PaymentInitData
  ): __Observable<__StrictHttpResponse<PaymentStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pmt/wallet/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentStatus>;
      })
    );
  }
  /**
   * Payment from wallet
   * @param data Operation data
   * @return Response status
   */
  incustControllersClientClientPaymentWalletPayment(
    data: PaymentInitData
  ): __Observable<PaymentStatus> {
    return this.incustControllersClientClientPaymentWalletPaymentResponse(
      data
    ).pipe(__map((_r) => _r.body as PaymentStatus));
  }

  /**
   * OTP verification for payment from wallet
   * @param data Operation data
   * @return Response status
   */
  incustControllersClientClientPaymentWalletPaymentOtpResponse(data: {
    id: string;
    otp_password: string;
  }): __Observable<__StrictHttpResponse<PaymentStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pmt/wallet/payment/otp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentStatus>;
      })
    );
  }
  /**
   * OTP verification for payment from wallet
   * @param data Operation data
   * @return Response status
   */
  incustControllersClientClientPaymentWalletPaymentOtp(data: {
    id: string;
    otp_password: string;
  }): __Observable<PaymentStatus> {
    return this.incustControllersClientClientPaymentWalletPaymentOtpResponse(
      data
    ).pipe(__map((_r) => _r.body as PaymentStatus));
  }

  /**
   * Request of status for payment from wallet
   * @param data Operation data
   * @return Response status
   */
  incustControllersClientClientPaymentWalletPaymentStatusResponse(data: {
    id: string;
  }): __Observable<__StrictHttpResponse<PaymentStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/pmt/wallet/payment/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentStatus>;
      })
    );
  }
  /**
   * Request of status for payment from wallet
   * @param data Operation data
   * @return Response status
   */
  incustControllersClientClientPaymentWalletPaymentStatus(data: {
    id: string;
  }): __Observable<PaymentStatus> {
    return this.incustControllersClientClientPaymentWalletPaymentStatusResponse(
      data
    ).pipe(__map((_r) => _r.body as PaymentStatus));
  }

  /**
   * Payment from wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletPaymentExParams` containing the following parameters:
   *
   * - `referrer`: Referrer
   *
   * - `data`: Operation data
   *
   * @return Response status
   */
  incustControllersClientClientPaymentWalletPaymentExResponse(
    params: PaymentService.IncustControllersClientClientPaymentWalletPaymentExParams
  ): __Observable<__StrictHttpResponse<PaymentStatus>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/pmt/wallet/payment/${encodeURIComponent(String(params.referrer))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentStatus>;
      })
    );
  }
  /**
   * Payment from wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletPaymentExParams` containing the following parameters:
   *
   * - `referrer`: Referrer
   *
   * - `data`: Operation data
   *
   * @return Response status
   */
  incustControllersClientClientPaymentWalletPaymentEx(
    params: PaymentService.IncustControllersClientClientPaymentWalletPaymentExParams
  ): __Observable<PaymentStatus> {
    return this.incustControllersClientClientPaymentWalletPaymentExResponse(
      params
    ).pipe(__map((_r) => _r.body as PaymentStatus));
  }

  /**
   * Returns mobile payment wallet by type
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletByTypeParams` containing the following parameters:
   *
   * - `wallet_type`: Type of payment wallet
   *
   * - `terminal_id`: Unique identifier of Terminal
   *
   * - `pos_id`: Unique identifier of POS
   *
   * - `loyalty_id`: Unique identifier of loyalty
   *
   * - `currency`: Currency
   *
   * @return Mobile payment wallet
   */
  incustControllersClientClientPaymentWalletByTypeResponse(
    params: PaymentService.IncustControllersClientClientPaymentWalletByTypeParams
  ): __Observable<__StrictHttpResponse<PaymentWallet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.terminalId != null)
      __params = __params.set('terminal_id', params.terminalId.toString());
    if (params.posId != null)
      __params = __params.set('pos_id', params.posId.toString());
    if (params.loyaltyId != null)
      __params = __params.set('loyalty_id', params.loyaltyId.toString());
    if (params.currency != null)
      __params = __params.set('currency', params.currency.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/pmt/wallet/${encodeURIComponent(String(params.walletType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentWallet>;
      })
    );
  }
  /**
   * Returns mobile payment wallet by type
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletByTypeParams` containing the following parameters:
   *
   * - `wallet_type`: Type of payment wallet
   *
   * - `terminal_id`: Unique identifier of Terminal
   *
   * - `pos_id`: Unique identifier of POS
   *
   * - `loyalty_id`: Unique identifier of loyalty
   *
   * - `currency`: Currency
   *
   * @return Mobile payment wallet
   */
  incustControllersClientClientPaymentWalletByType(
    params: PaymentService.IncustControllersClientClientPaymentWalletByTypeParams
  ): __Observable<PaymentWallet> {
    return this.incustControllersClientClientPaymentWalletByTypeResponse(
      params
    ).pipe(__map((_r) => _r.body as PaymentWallet));
  }

  /**
   * Manage payment wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletManageWDataParams` containing the following parameters:
   *
   * - `wallet_type`: Type of payment wallet
   *
   * - `data`: Operation data
   *
   * - `action`: Action type
   *
   * @return Response object (URL or status)
   */
  incustControllersClientClientPaymentWalletManageWDataResponse(
    params: PaymentService.IncustControllersClientClientPaymentWalletManageWDataParams
  ): __Observable<__StrictHttpResponse<PaymentCardManageResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/pmt/wallet/${encodeURIComponent(
          String(params.walletType)
        )}/${encodeURIComponent(String(params.action))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentCardManageResult>;
      })
    );
  }
  /**
   * Manage payment wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletManageWDataParams` containing the following parameters:
   *
   * - `wallet_type`: Type of payment wallet
   *
   * - `data`: Operation data
   *
   * - `action`: Action type
   *
   * @return Response object (URL or status)
   */
  incustControllersClientClientPaymentWalletManageWData(
    params: PaymentService.IncustControllersClientClientPaymentWalletManageWDataParams
  ): __Observable<PaymentCardManageResult> {
    return this.incustControllersClientClientPaymentWalletManageWDataResponse(
      params
    ).pipe(__map((_r) => _r.body as PaymentCardManageResult));
  }

  /**
   * Manage payment wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletManageWDataExParams` containing the following parameters:
   *
   * - `wallet_type`: Type of payment wallet
   *
   * - `referrer`: Referrer
   *
   * - `data`: Operation data
   *
   * - `action`: Action type
   *
   * @return Response object (URL or status)
   */
  incustControllersClientClientPaymentWalletManageWDataExResponse(
    params: PaymentService.IncustControllersClientClientPaymentWalletManageWDataExParams
  ): __Observable<__StrictHttpResponse<PaymentCardManageResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/pmt/wallet/${encodeURIComponent(
          String(params.walletType)
        )}/${encodeURIComponent(String(params.action))}/${encodeURIComponent(
          String(params.referrer)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PaymentCardManageResult>;
      })
    );
  }
  /**
   * Manage payment wallet
   * @param params The `PaymentService.IncustControllersClientClientPaymentWalletManageWDataExParams` containing the following parameters:
   *
   * - `wallet_type`: Type of payment wallet
   *
   * - `referrer`: Referrer
   *
   * - `data`: Operation data
   *
   * - `action`: Action type
   *
   * @return Response object (URL or status)
   */
  incustControllersClientClientPaymentWalletManageWDataEx(
    params: PaymentService.IncustControllersClientClientPaymentWalletManageWDataExParams
  ): __Observable<PaymentCardManageResult> {
    return this.incustControllersClientClientPaymentWalletManageWDataExResponse(
      params
    ).pipe(__map((_r) => _r.body as PaymentCardManageResult));
  }
}

module PaymentService {
  /**
   * Parameters for incustControllersClientClientPaymentInfoByTerminal
   */
  export interface IncustControllersClientClientPaymentInfoByTerminalParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Include transaction info
     */
    transactionInfo?: null | boolean;

    /**
     * Include goods item info
     */
    goodsItemInfo?: null | boolean;
  }

  /**
   * Parameters for incustControllersClientClientPaymentInfoByTransaction
   */
  export interface IncustControllersClientClientPaymentInfoByTransactionParams {
    /**
     * Transaction ID
     */
    transactionId: string;

    /**
     * Include transaction info
     */
    transactionInfo?: null | boolean;

    /**
     * Include goods item info
     */
    goodsItemInfo?: null | boolean;
  }

  /**
   * Parameters for incustControllersClientClientPaymentInfo
   */
  export interface IncustControllersClientClientPaymentInfoParams {
    /**
     * Payment ID
     */
    paymentId: string;

    /**
     * Include transaction info
     */
    transactionInfo?: null | boolean;

    /**
     * Include goods item info
     */
    goodsItemInfo?: null | boolean;
  }

  /**
   * Parameters for incustControllersClientClientPaymentsByTransactions
   */
  export interface IncustControllersClientClientPaymentsByTransactionsParams {
    /**
     * Transaction ID
     */
    transactionId: string;

    /**
     * Include transaction info
     */
    transactionInfo?: null | boolean;

    /**
     * Include goods item info
     */
    goodsItemInfo?: null | boolean;
  }

  /**
   * Parameters for incustControllersClientClientPaymentWallet
   */
  export interface IncustControllersClientClientPaymentWalletParams {
    /**
     * Unique identifier of Terminal
     */
    terminalId?: null | string;

    /**
     * Unique identifier of POS
     */
    posId?: null | string;

    /**
     * Unique identifier of loyalty
     */
    loyaltyId?: null | string;

    /**
     * Currency
     */
    currency?: null | string;
  }

  /**
   * Parameters for incustControllersClientClientPaymentWalletCardPassword
   */
  export interface IncustControllersClientClientPaymentWalletCardPasswordParams {
    /**
     * Operation data
     */
    data: { cards: Array<string>; otppassword?: string; password?: string };

    /**
     * Action type
     */
    action: 'set' | 'reset';
  }

  /**
   * Parameters for incustControllersClientClientPaymentWalletPaymentEx
   */
  export interface IncustControllersClientClientPaymentWalletPaymentExParams {
    /**
     * Referrer
     */
    referrer: string;

    /**
     * Operation data
     */
    data: PaymentInitData;
  }

  /**
   * Parameters for incustControllersClientClientPaymentWalletByType
   */
  export interface IncustControllersClientClientPaymentWalletByTypeParams {
    /**
     * Type of payment wallet
     */
    walletType:
      | 'ipay'
      | 'stripe'
      | '4bill'
      | 'eway'
      | 'flutterwave'
      | 'global-payments'
      | 'wave';

    /**
     * Unique identifier of Terminal
     */
    terminalId?: null | string;

    /**
     * Unique identifier of POS
     */
    posId?: null | string;

    /**
     * Unique identifier of loyalty
     */
    loyaltyId?: null | string;

    /**
     * Currency
     */
    currency?: null | string;
  }

  /**
   * Parameters for incustControllersClientClientPaymentWalletManageWData
   */
  export interface IncustControllersClientClientPaymentWalletManageWDataParams {
    /**
     * Type of payment wallet
     */
    walletType:
      | 'ipay'
      | 'stripe'
      | '4bill'
      | 'eway'
      | 'flutterwave'
      | 'global-payments'
      | 'wave';

    /**
     * Operation data
     */
    data: PaymentCardManage;

    /**
     * Action type
     */
    action: 'register' | 'invite' | 'cardadd' | 'carddelete';
  }

  /**
   * Parameters for incustControllersClientClientPaymentWalletManageWDataEx
   */
  export interface IncustControllersClientClientPaymentWalletManageWDataExParams {
    /**
     * Type of payment wallet
     */
    walletType:
      | 'ipay'
      | 'stripe'
      | '4bill'
      | 'eway'
      | 'flutterwave'
      | 'global-payments'
      | 'wave';

    /**
     * Referrer
     */
    referrer: string;

    /**
     * Operation data
     */
    data: PaymentCardManage;

    /**
     * Action type
     */
    action: 'register' | 'invite' | 'cardadd' | 'carddelete';
  }
}

export { PaymentService };
