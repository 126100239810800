/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Job } from '../models/job';
@Injectable({
  providedIn: 'root'
})
class JobsService extends __BaseService {
  static readonly incustControllersClientClientActiveJobGetPath =
    '/jobs/active';
  static readonly incustControllersClientClientJobsByTransactionPath =
    '/jobs/transaction/{transaction_id}';
  static readonly incustControllersClientClientJobsByTransactionAddPath =
    '/jobs/transaction/{transaction_id}';
  static readonly incustControllersClientClientJobGetPath = '/jobs/{job_id}';
  static readonly incustControllersClientClientJobStartPath =
    '/jobs/{job_id}/start';
  static readonly incustControllersClientClientJobStopPath =
    '/jobs/{job_id}/stop';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Get not finished yet (active) jobs info
   * @param loyalty_id Loyalty ID
   * @return Job objects array
   */
  incustControllersClientClientActiveJobGetResponse(
    loyaltyId?: string
  ): __Observable<__StrictHttpResponse<Array<Job>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (loyaltyId != null)
      __params = __params.set('loyalty_id', loyaltyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/jobs/active`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Job>>;
      })
    );
  }
  /**
   * Get not finished yet (active) jobs info
   * @param loyalty_id Loyalty ID
   * @return Job objects array
   */
  incustControllersClientClientActiveJobGet(
    loyaltyId?: string
  ): __Observable<Array<Job>> {
    return this.incustControllersClientClientActiveJobGetResponse(
      loyaltyId
    ).pipe(__map((_r) => _r.body as Array<Job>));
  }

  /**
   * Get transaction jobs list
   * @param transaction_id Transaction ID
   * @return Job objects array
   */
  incustControllersClientClientJobsByTransactionResponse(
    transactionId: string
  ): __Observable<__StrictHttpResponse<Array<Job>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/jobs/transaction/${encodeURIComponent(String(transactionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Job>>;
      })
    );
  }
  /**
   * Get transaction jobs list
   * @param transaction_id Transaction ID
   * @return Job objects array
   */
  incustControllersClientClientJobsByTransaction(
    transactionId: string
  ): __Observable<Array<Job>> {
    return this.incustControllersClientClientJobsByTransactionResponse(
      transactionId
    ).pipe(__map((_r) => _r.body as Array<Job>));
  }

  /**
   * Add job for transaction
   * @param params The `JobsService.IncustControllersClientClientJobsByTransactionAddParams` containing the following parameters:
   *
   * - `transaction_id`: Transaction ID
   *
   * - `data`: Job object
   *
   * @return Job object
   */
  incustControllersClientClientJobsByTransactionAddResponse(
    params: JobsService.IncustControllersClientClientJobsByTransactionAddParams
  ): __Observable<__StrictHttpResponse<Job>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/jobs/transaction/${encodeURIComponent(String(params.transactionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Job>;
      })
    );
  }
  /**
   * Add job for transaction
   * @param params The `JobsService.IncustControllersClientClientJobsByTransactionAddParams` containing the following parameters:
   *
   * - `transaction_id`: Transaction ID
   *
   * - `data`: Job object
   *
   * @return Job object
   */
  incustControllersClientClientJobsByTransactionAdd(
    params: JobsService.IncustControllersClientClientJobsByTransactionAddParams
  ): __Observable<Job> {
    return this.incustControllersClientClientJobsByTransactionAddResponse(
      params
    ).pipe(__map((_r) => _r.body as Job));
  }

  /**
   * Get job info
   * @param job_id Job ID
   * @return Job object
   */
  incustControllersClientClientJobGetResponse(
    jobId: string
  ): __Observable<__StrictHttpResponse<Job>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/jobs/${encodeURIComponent(String(jobId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Job>;
      })
    );
  }
  /**
   * Get job info
   * @param job_id Job ID
   * @return Job object
   */
  incustControllersClientClientJobGet(jobId: string): __Observable<Job> {
    return this.incustControllersClientClientJobGetResponse(jobId).pipe(
      __map((_r) => _r.body as Job)
    );
  }

  /**
   * Job start
   * @param params The `JobsService.IncustControllersClientClientJobStartParams` containing the following parameters:
   *
   * - `job_id`: Job ID
   *
   * - `data`: Command details
   *
   * @return Job object
   */
  incustControllersClientClientJobStartResponse(
    params: JobsService.IncustControllersClientClientJobStartParams
  ): __Observable<__StrictHttpResponse<Job>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/jobs/${encodeURIComponent(String(params.jobId))}/start`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Job>;
      })
    );
  }
  /**
   * Job start
   * @param params The `JobsService.IncustControllersClientClientJobStartParams` containing the following parameters:
   *
   * - `job_id`: Job ID
   *
   * - `data`: Command details
   *
   * @return Job object
   */
  incustControllersClientClientJobStart(
    params: JobsService.IncustControllersClientClientJobStartParams
  ): __Observable<Job> {
    return this.incustControllersClientClientJobStartResponse(params).pipe(
      __map((_r) => _r.body as Job)
    );
  }

  /**
   * Job stop
   * @param params The `JobsService.IncustControllersClientClientJobStopParams` containing the following parameters:
   *
   * - `job_id`: Job ID
   *
   * - `data`: Command details
   *
   * @return Job object
   */
  incustControllersClientClientJobStopResponse(
    params: JobsService.IncustControllersClientClientJobStopParams
  ): __Observable<__StrictHttpResponse<Job>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/jobs/${encodeURIComponent(String(params.jobId))}/stop`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Job>;
      })
    );
  }
  /**
   * Job stop
   * @param params The `JobsService.IncustControllersClientClientJobStopParams` containing the following parameters:
   *
   * - `job_id`: Job ID
   *
   * - `data`: Command details
   *
   * @return Job object
   */
  incustControllersClientClientJobStop(
    params: JobsService.IncustControllersClientClientJobStopParams
  ): __Observable<Job> {
    return this.incustControllersClientClientJobStopResponse(params).pipe(
      __map((_r) => _r.body as Job)
    );
  }
}

module JobsService {
  /**
   * Parameters for incustControllersClientClientJobsByTransactionAdd
   */
  export interface IncustControllersClientClientJobsByTransactionAddParams {
    /**
     * Transaction ID
     */
    transactionId: string;

    /**
     * Job object
     */
    data: Job;
  }

  /**
   * Parameters for incustControllersClientClientJobStart
   */
  export interface IncustControllersClientClientJobStartParams {
    /**
     * Job ID
     */
    jobId: string;

    /**
     * Command details
     */
    data: { comment?: string };
  }

  /**
   * Parameters for incustControllersClientClientJobStop
   */
  export interface IncustControllersClientClientJobStopParams {
    /**
     * Job ID
     */
    jobId: string;

    /**
     * Command details
     */
    data: { comment?: string };
  }
}

export { JobsService };
