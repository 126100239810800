import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CouponService } from '../../api/services';
import { AsyncPipe } from '@angular/common';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { Coupon } from '../../api/models/coupon';

@Component({
  selector: 'app-coupon-view',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './coupon.component.html',
  styleUrl: './coupon.component.scss'
})
export class CouponComponent implements OnInit {
  protected readonly environment = environment;
  protected readonly destroyRef = inject(DestroyRef);
  private couponService = inject(CouponService);
  private metaService = inject(Meta);
  private titleService = inject(Title);
  private route = inject(ActivatedRoute);
  private destroy$ = new Subject<void>();

  public coupon$?: Observable<Coupon>;

  ngOnInit() {
    this.fetchData();

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  fetchData() {
    console.log(this.route.snapshot);
    const couponId = this.route.snapshot.paramMap.get('couponId');

    if (!couponId) {
      return;
    }

    this.coupon$ = this.couponService
      .incustControllersClientClientCouponInfo(couponId)
      .pipe(
        takeUntil(this.destroy$),
        map((res: Coupon) => {
          console.log('Coupon: ', res);
          this.setMetaTags(res);
          // window.open(
          //   `${environment.CLIENT_URL}/coupon/show/${res.code}?lang=${
          //     lang || 'en'
          //   }`,
          //   '_self'
          // );
          return res;
        })
      );
  }

  getUrl(code?: string | null) {
    if (!code) {
      return '';
    }

    const lang = this.route.snapshot.queryParamMap.get('lang') || null;
    const from = this.route.snapshot.queryParamMap.get('from_src') || null;
    return `${
      from ? 'https://' + from : environment.CLIENT_URL
    }/coupon/show/${code}?lang=${lang || 'en'}`;
  }

  setMetaTags(data: Coupon) {
    this.titleService.setTitle(data.title || 'Default Title');
    this.metaService.addTags([
      {
        name: 'description',
        content: data.description || 'Default description'
      },
      { name: 'keywords', content: data.title || 'angular,ssr,example' },
      { name: 'twitter:card', content: 'summary' },
      // {name: 'twitter:site', content: `@`},
      { name: 'og:type', content: 'website' },
      { name: 'og:site_name', content: data.title || 'Default Title' },
      { name: 'og:title', content: data.title || 'Default Title' },
      {
        name: 'og:url',
        content: this.getUrl(data.code)
      },
      {
        name: 'og:description',
        content: data.description || 'Default description'
      },
      { name: 'og:image', content: data.image || '' },
      { name: 'og:image:width', content: '600' },
      { name: 'og:image:height', content: '315' }
    ]);
    this.metaService.updateTag({
      rel: 'canonical',
      href: this.getUrl(data.code)
    });
  }
}
