/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CardRecord } from '../models/card-record';
@Injectable({
  providedIn: 'root'
})
class CardsService extends __BaseService {
  static readonly incustControllersClientClientCustomerCardsPath = '/cards';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return customer cards
   * @return Customer cards array
   */
  incustControllersClientClientCustomerCardsResponse(): __Observable<
    __StrictHttpResponse<Array<CardRecord>>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/cards`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CardRecord>>;
      })
    );
  }
  /**
   * Return customer cards
   * @return Customer cards array
   */
  incustControllersClientClientCustomerCards(): __Observable<
    Array<CardRecord>
  > {
    return this.incustControllersClientClientCustomerCardsResponse().pipe(
      __map((_r) => _r.body as Array<CardRecord>)
    );
  }
}

module CardsService {}

export { CardsService };
