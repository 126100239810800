/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CardInfo } from '../models/card-info';
@Injectable({
  providedIn: 'root'
})
class CardInfoService extends __BaseService {
  static readonly incustControllersClientClientCardInfoPath = '/card_info';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Возвращает общую (вне зависимости от бизнеса, валюты и т.д.) информацию по карточке покупателя
   * @return Card Info
   */
  incustControllersClientClientCardInfoResponse(): __Observable<
    __StrictHttpResponse<CardInfo>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/card_info`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CardInfo>;
      })
    );
  }
  /**
   * Возвращает общую (вне зависимости от бизнеса, валюты и т.д.) информацию по карточке покупателя
   * @return Card Info
   */
  incustControllersClientClientCardInfo(): __Observable<CardInfo> {
    return this.incustControllersClientClientCardInfoResponse().pipe(
      __map((_r) => _r.body as CardInfo)
    );
  }
}

module CardInfoService {}

export { CardInfoService };
