/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReferralProgramCodeInfo } from '../models/referral-program-code-info';
import { MessageResponse } from '../models/message-response';
import { Loyalty } from '../models/loyalty';
import { LoyaltyCustomer } from '../models/loyalty-customer';
import { TransferFundsResponse } from '../models/transfer-funds-response';
import { CustomerAccountTransferFundsRequest } from '../models/customer-account-transfer-funds-request';
import { BonusPointsTransferFundsRequest } from '../models/bonus-points-transfer-funds-request';
import { CardRecord } from '../models/card-record';
import { ReferralProgramChain } from '../models/referral-program-chain';
import { ReferralProgramCode } from '../models/referral-program-code';
import { ReferralProgramSummary } from '../models/referral-program-summary';
import { LoyaltySettings } from '../models/loyalty-settings';
@Injectable({
  providedIn: 'root'
})
class LoyaltyService extends __BaseService {
  static readonly incustControllersClientClientReferralProgramInvitationInfoPath =
    '/loyalty/referral_program/invitation/{code}';
  static readonly incustControllersClientClientReferralProgramInvitationPath =
    '/loyalty/referral_program/invitation/{code}';
  static readonly incustControllersClientClientLoyaltyPath =
    '/loyalty/{loyalty_id}';
  static readonly incustControllersClientClientLoyaltyCustomerAddPath =
    '/loyalty/{loyalty_id}/customers/add';
  static readonly incustControllersClientClientLoyaltyCustomerFundsTransferAccountsPath =
    '/loyalty/{loyalty_id}/customers/benefits/transfer/accounts';
  static readonly incustControllersClientClientLoyaltyCustomerFundsTransferBonusesPath =
    '/loyalty/{loyalty_id}/customers/benefits/transfer/bonuses';
  static readonly incustControllersClientClientLoyaltyCustomerCardsPath =
    '/loyalty/{loyalty_id}/customers/cards';
  static readonly incustControllersClientClientLoyaltyCustomerCardAddPath =
    '/loyalty/{loyalty_id}/customers/cards';
  static readonly incustControllersClientClientReferralProgramChainPath =
    '/loyalty/{loyalty_id}/referral_program/chain';
  static readonly incustControllersClientClientReferralProgramCodeGetPath =
    '/loyalty/{loyalty_id}/referral_program/code';
  static readonly incustControllersClientClientReferralProgramSummaryPath =
    '/loyalty/{loyalty_id}/referral_program/summary';
  static readonly incustControllersClientClientLoyaltySettingsPath =
    '/loyalty/{loyalty_id}/settings';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return invite loyalty referral program code info
   * @param code referral program code
   * @return Loyalty referral program code info object
   */
  incustControllersClientClientReferralProgramInvitationInfoResponse(
    code: string
  ): __Observable<__StrictHttpResponse<ReferralProgramCodeInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/loyalty/referral_program/invitation/${encodeURIComponent(
          String(code)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralProgramCodeInfo>;
      })
    );
  }
  /**
   * Return invite loyalty referral program code info
   * @param code referral program code
   * @return Loyalty referral program code info object
   */
  incustControllersClientClientReferralProgramInvitationInfo(
    code: string
  ): __Observable<ReferralProgramCodeInfo> {
    return this.incustControllersClientClientReferralProgramInvitationInfoResponse(
      code
    ).pipe(__map((_r) => _r.body as ReferralProgramCodeInfo));
  }

  /**
   * Loyalty referral program code invitation accept or reject
   * @param params The `LoyaltyService.IncustControllersClientClientReferralProgramInvitationParams` containing the following parameters:
   *
   * - `data`: Code and action data
   *
   * - `code`: referral program code
   *
   * @return Ok
   */
  incustControllersClientClientReferralProgramInvitationResponse(
    params: LoyaltyService.IncustControllersClientClientReferralProgramInvitationParams
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/loyalty/referral_program/invitation/${encodeURIComponent(
          String(params.code)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Loyalty referral program code invitation accept or reject
   * @param params The `LoyaltyService.IncustControllersClientClientReferralProgramInvitationParams` containing the following parameters:
   *
   * - `data`: Code and action data
   *
   * - `code`: referral program code
   *
   * @return Ok
   */
  incustControllersClientClientReferralProgramInvitation(
    params: LoyaltyService.IncustControllersClientClientReferralProgramInvitationParams
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientReferralProgramInvitationResponse(
      params
    ).pipe(__map((_r) => _r.body as MessageResponse));
  }

  /**
   * Loyalty for client by ID
   * @param params The `LoyaltyService.IncustControllersClientClientLoyaltyParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `sort_by_priority`: Sort POS by priority (0/1)
   *
   * - `road_tankers`: Return limited road tanker pos list
   *
   * - `pos`: Return limited pos list
   *
   * - `online_stores`: Return limited online store pos (warehouse) list
   *
   * - `longitude`: Client longitude
   *
   * - `latitude`: Client latitude
   *
   * - `customer_access`: Return customer access type info (0/1)
   *
   * - `coupons`: Return coupons info (0/1)
   *
   * - `cards`: Return cards info (0/1)
   *
   * - `card_categories`: Return card categories info (0/1)
   *
   * - `bonuses`: Return bonuses and accounts info (0/1)
   *
   * @return Loyalty object
   */
  incustControllersClientClientLoyaltyResponse(
    params: LoyaltyService.IncustControllersClientClientLoyaltyParams
  ): __Observable<__StrictHttpResponse<Loyalty>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sortByPriority != null)
      __params = __params.set(
        'sort_by_priority',
        params.sortByPriority.toString()
      );
    if (params.roadTankers != null)
      __params = __params.set('road_tankers', params.roadTankers.toString());
    if (params.pos != null)
      __params = __params.set('pos', params.pos.toString());
    if (params.onlineStores != null)
      __params = __params.set('online_stores', params.onlineStores.toString());
    if (params.longitude != null)
      __params = __params.set('longitude', params.longitude.toString());
    if (params.latitude != null)
      __params = __params.set('latitude', params.latitude.toString());
    if (params.customerAccess != null)
      __params = __params.set(
        'customer_access',
        params.customerAccess.toString()
      );
    if (params.coupons != null)
      __params = __params.set('coupons', params.coupons.toString());
    if (params.cards != null)
      __params = __params.set('cards', params.cards.toString());
    if (params.cardCategories != null)
      __params = __params.set(
        'card_categories',
        params.cardCategories.toString()
      );
    if (params.bonuses != null)
      __params = __params.set('bonuses', params.bonuses.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/loyalty/${encodeURIComponent(String(params.loyaltyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Loyalty>;
      })
    );
  }
  /**
   * Loyalty for client by ID
   * @param params The `LoyaltyService.IncustControllersClientClientLoyaltyParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `sort_by_priority`: Sort POS by priority (0/1)
   *
   * - `road_tankers`: Return limited road tanker pos list
   *
   * - `pos`: Return limited pos list
   *
   * - `online_stores`: Return limited online store pos (warehouse) list
   *
   * - `longitude`: Client longitude
   *
   * - `latitude`: Client latitude
   *
   * - `customer_access`: Return customer access type info (0/1)
   *
   * - `coupons`: Return coupons info (0/1)
   *
   * - `cards`: Return cards info (0/1)
   *
   * - `card_categories`: Return card categories info (0/1)
   *
   * - `bonuses`: Return bonuses and accounts info (0/1)
   *
   * @return Loyalty object
   */
  incustControllersClientClientLoyalty(
    params: LoyaltyService.IncustControllersClientClientLoyaltyParams
  ): __Observable<Loyalty> {
    return this.incustControllersClientClientLoyaltyResponse(params).pipe(
      __map((_r) => _r.body as Loyalty)
    );
  }

  /**
   * Add customer to the loyalty
   * @param loyalty_id Loyalty ID
   * @return Customer data
   */
  incustControllersClientClientLoyaltyCustomerAddResponse(
    loyaltyId: string
  ): __Observable<__StrictHttpResponse<LoyaltyCustomer>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(String(loyaltyId))}/customers/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoyaltyCustomer>;
      })
    );
  }
  /**
   * Add customer to the loyalty
   * @param loyalty_id Loyalty ID
   * @return Customer data
   */
  incustControllersClientClientLoyaltyCustomerAdd(
    loyaltyId: string
  ): __Observable<LoyaltyCustomer> {
    return this.incustControllersClientClientLoyaltyCustomerAddResponse(
      loyaltyId
    ).pipe(__map((_r) => _r.body as LoyaltyCustomer));
  }

  /**
   * Transfer account funds to another customer
   * @param params The `LoyaltyService.IncustControllersClientClientLoyaltyCustomerFundsTransferAccountsParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Transfer data
   *
   * @return Transfer data
   */
  incustControllersClientClientLoyaltyCustomerFundsTransferAccountsResponse(
    params: LoyaltyService.IncustControllersClientClientLoyaltyCustomerFundsTransferAccountsParams
  ): __Observable<__StrictHttpResponse<TransferFundsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(
          String(params.loyaltyId)
        )}/customers/benefits/transfer/accounts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferFundsResponse>;
      })
    );
  }
  /**
   * Transfer account funds to another customer
   * @param params The `LoyaltyService.IncustControllersClientClientLoyaltyCustomerFundsTransferAccountsParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Transfer data
   *
   * @return Transfer data
   */
  incustControllersClientClientLoyaltyCustomerFundsTransferAccounts(
    params: LoyaltyService.IncustControllersClientClientLoyaltyCustomerFundsTransferAccountsParams
  ): __Observable<TransferFundsResponse> {
    return this.incustControllersClientClientLoyaltyCustomerFundsTransferAccountsResponse(
      params
    ).pipe(__map((_r) => _r.body as TransferFundsResponse));
  }

  /**
   * Transfer bonus points to another customer
   * @param params The `LoyaltyService.IncustControllersClientClientLoyaltyCustomerFundsTransferBonusesParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Transfer data
   *
   * @return Transfer data
   */
  incustControllersClientClientLoyaltyCustomerFundsTransferBonusesResponse(
    params: LoyaltyService.IncustControllersClientClientLoyaltyCustomerFundsTransferBonusesParams
  ): __Observable<__StrictHttpResponse<TransferFundsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(
          String(params.loyaltyId)
        )}/customers/benefits/transfer/bonuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransferFundsResponse>;
      })
    );
  }
  /**
   * Transfer bonus points to another customer
   * @param params The `LoyaltyService.IncustControllersClientClientLoyaltyCustomerFundsTransferBonusesParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Transfer data
   *
   * @return Transfer data
   */
  incustControllersClientClientLoyaltyCustomerFundsTransferBonuses(
    params: LoyaltyService.IncustControllersClientClientLoyaltyCustomerFundsTransferBonusesParams
  ): __Observable<TransferFundsResponse> {
    return this.incustControllersClientClientLoyaltyCustomerFundsTransferBonusesResponse(
      params
    ).pipe(__map((_r) => _r.body as TransferFundsResponse));
  }

  /**
   * Return loyalty customer cards
   * @param loyalty_id Loyalty ID
   * @return Customer cards array
   */
  incustControllersClientClientLoyaltyCustomerCardsResponse(
    loyaltyId: string
  ): __Observable<__StrictHttpResponse<Array<CardRecord>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(String(loyaltyId))}/customers/cards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CardRecord>>;
      })
    );
  }
  /**
   * Return loyalty customer cards
   * @param loyalty_id Loyalty ID
   * @return Customer cards array
   */
  incustControllersClientClientLoyaltyCustomerCards(
    loyaltyId: string
  ): __Observable<Array<CardRecord>> {
    return this.incustControllersClientClientLoyaltyCustomerCardsResponse(
      loyaltyId
    ).pipe(__map((_r) => _r.body as Array<CardRecord>));
  }

  /**
   * Add loyalty customer card
   * @param params The `LoyaltyService.IncustControllersClientClientLoyaltyCustomerCardAddParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Card code
   *
   * @return Customer cards array
   */
  incustControllersClientClientLoyaltyCustomerCardAddResponse(
    params: LoyaltyService.IncustControllersClientClientLoyaltyCustomerCardAddParams
  ): __Observable<__StrictHttpResponse<Array<CardRecord>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(
          String(params.loyaltyId)
        )}/customers/cards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CardRecord>>;
      })
    );
  }
  /**
   * Add loyalty customer card
   * @param params The `LoyaltyService.IncustControllersClientClientLoyaltyCustomerCardAddParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Card code
   *
   * @return Customer cards array
   */
  incustControllersClientClientLoyaltyCustomerCardAdd(
    params: LoyaltyService.IncustControllersClientClientLoyaltyCustomerCardAddParams
  ): __Observable<Array<CardRecord>> {
    return this.incustControllersClientClientLoyaltyCustomerCardAddResponse(
      params
    ).pipe(__map((_r) => _r.body as Array<CardRecord>));
  }

  /**
   * Return loyalty referral program referral chain
   * @param params The `LoyaltyService.IncustControllersClientClientReferralProgramChainParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `rewards`: Include rewards in result
   *
   * @return Loyalty referral program chain info
   */
  incustControllersClientClientReferralProgramChainResponse(
    params: LoyaltyService.IncustControllersClientClientReferralProgramChainParams
  ): __Observable<__StrictHttpResponse<ReferralProgramChain>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.rewards != null)
      __params = __params.set('rewards', params.rewards.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(
          String(params.loyaltyId)
        )}/referral_program/chain`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralProgramChain>;
      })
    );
  }
  /**
   * Return loyalty referral program referral chain
   * @param params The `LoyaltyService.IncustControllersClientClientReferralProgramChainParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `rewards`: Include rewards in result
   *
   * @return Loyalty referral program chain info
   */
  incustControllersClientClientReferralProgramChain(
    params: LoyaltyService.IncustControllersClientClientReferralProgramChainParams
  ): __Observable<ReferralProgramChain> {
    return this.incustControllersClientClientReferralProgramChainResponse(
      params
    ).pipe(__map((_r) => _r.body as ReferralProgramChain));
  }

  /**
   * Emit and return loyalty referral program code
   * @param loyalty_id Loyalty ID
   * @return Loyalty referral program code object
   */
  incustControllersClientClientReferralProgramCodeGetResponse(
    loyaltyId: string
  ): __Observable<__StrictHttpResponse<ReferralProgramCode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(
          String(loyaltyId)
        )}/referral_program/code`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralProgramCode>;
      })
    );
  }
  /**
   * Emit and return loyalty referral program code
   * @param loyalty_id Loyalty ID
   * @return Loyalty referral program code object
   */
  incustControllersClientClientReferralProgramCodeGet(
    loyaltyId: string
  ): __Observable<ReferralProgramCode> {
    return this.incustControllersClientClientReferralProgramCodeGetResponse(
      loyaltyId
    ).pipe(__map((_r) => _r.body as ReferralProgramCode));
  }

  /**
   * Return loyalty referral program summary information
   * @param loyalty_id Loyalty ID
   * @return Loyalty referral program summary info
   */
  incustControllersClientClientReferralProgramSummaryResponse(
    loyaltyId: string
  ): __Observable<__StrictHttpResponse<ReferralProgramSummary>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(
          String(loyaltyId)
        )}/referral_program/summary`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReferralProgramSummary>;
      })
    );
  }
  /**
   * Return loyalty referral program summary information
   * @param loyalty_id Loyalty ID
   * @return Loyalty referral program summary info
   */
  incustControllersClientClientReferralProgramSummary(
    loyaltyId: string
  ): __Observable<ReferralProgramSummary> {
    return this.incustControllersClientClientReferralProgramSummaryResponse(
      loyaltyId
    ).pipe(__map((_r) => _r.body as ReferralProgramSummary));
  }

  /**
   * Loyalty settings by ID
   * @param loyalty_id Loyalty ID
   * @return Loyalty settings object
   */
  incustControllersClientClientLoyaltySettingsResponse(
    loyaltyId: string
  ): __Observable<__StrictHttpResponse<LoyaltySettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(String(loyaltyId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LoyaltySettings>;
      })
    );
  }
  /**
   * Loyalty settings by ID
   * @param loyalty_id Loyalty ID
   * @return Loyalty settings object
   */
  incustControllersClientClientLoyaltySettings(
    loyaltyId: string
  ): __Observable<LoyaltySettings> {
    return this.incustControllersClientClientLoyaltySettingsResponse(
      loyaltyId
    ).pipe(__map((_r) => _r.body as LoyaltySettings));
  }
}

module LoyaltyService {
  /**
   * Parameters for incustControllersClientClientReferralProgramInvitation
   */
  export interface IncustControllersClientClientReferralProgramInvitationParams {
    /**
     * Code and action data
     */
    data: { action: 'accept' | 'reject' };

    /**
     * referral program code
     */
    code: string;
  }

  /**
   * Parameters for incustControllersClientClientLoyalty
   */
  export interface IncustControllersClientClientLoyaltyParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Sort POS by priority (0/1)
     */
    sortByPriority?: 0 | 1;

    /**
     * Return limited road tanker pos list
     */
    roadTankers?: number;

    /**
     * Return limited pos list
     */
    pos?: number;

    /**
     * Return limited online store pos (warehouse) list
     */
    onlineStores?: number;

    /**
     * Client longitude
     */
    longitude?: number;

    /**
     * Client latitude
     */
    latitude?: number;

    /**
     * Return customer access type info (0/1)
     */
    customerAccess?: 0 | 1;

    /**
     * Return coupons info (0/1)
     */
    coupons?: 0 | 1;

    /**
     * Return cards info (0/1)
     */
    cards?: 0 | 1;

    /**
     * Return card categories info (0/1)
     */
    cardCategories?: 0 | 1;

    /**
     * Return bonuses and accounts info (0/1)
     */
    bonuses?: 0 | 1;
  }

  /**
   * Parameters for incustControllersClientClientLoyaltyCustomerFundsTransferAccounts
   */
  export interface IncustControllersClientClientLoyaltyCustomerFundsTransferAccountsParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Transfer data
     */
    data: CustomerAccountTransferFundsRequest;
  }

  /**
   * Parameters for incustControllersClientClientLoyaltyCustomerFundsTransferBonuses
   */
  export interface IncustControllersClientClientLoyaltyCustomerFundsTransferBonusesParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Transfer data
     */
    data: BonusPointsTransferFundsRequest;
  }

  /**
   * Parameters for incustControllersClientClientLoyaltyCustomerCardAdd
   */
  export interface IncustControllersClientClientLoyaltyCustomerCardAddParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Card code
     */
    data: { code: string };
  }

  /**
   * Parameters for incustControllersClientClientReferralProgramChain
   */
  export interface IncustControllersClientClientReferralProgramChainParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Include rewards in result
     */
    rewards?: boolean;
  }
}

export { LoyaltyService };
