/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MessageResponse } from '../models/message-response';
@Injectable({
  providedIn: 'root'
})
class QrCodeService extends __BaseService {
  static readonly incustControllersClientClientQrcodePostPath = '/qrcode';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Take Code from client
   * @param data QRCode data
   * @return Ok
   */
  incustControllersClientClientQrcodePostResponse(data: {
    code: string;
    type?: any;
  }): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/qrcode`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Take Code from client
   * @param data QRCode data
   * @return Ok
   */
  incustControllersClientClientQrcodePost(data: {
    code: string;
    type?: any;
  }): __Observable<MessageResponse> {
    return this.incustControllersClientClientQrcodePostResponse(data).pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }
}

module QrCodeService {}

export { QrCodeService };
