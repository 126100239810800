/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
class PkpassService extends __BaseService {
  static readonly incustControllersClientClientPkpassLoyaltyCustomersCardGeneratePath =
    '/pkpass/loyalty/{loyalty_id}/customers/card/{card_id}/generate';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Generate and return .pkpass for loyalty customers card
   * @param params The `PkpassService.IncustControllersClientClientPkpassLoyaltyCustomersCardGenerateParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `card_id`: Customers card ID
   */
  incustControllersClientClientPkpassLoyaltyCustomersCardGenerateResponse(
    params: PkpassService.IncustControllersClientClientPkpassLoyaltyCustomersCardGenerateParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/pkpass/loyalty/${encodeURIComponent(
          String(params.loyaltyId)
        )}/customers/card/${encodeURIComponent(
          String(params.cardId)
        )}/generate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Generate and return .pkpass for loyalty customers card
   * @param params The `PkpassService.IncustControllersClientClientPkpassLoyaltyCustomersCardGenerateParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `card_id`: Customers card ID
   */
  incustControllersClientClientPkpassLoyaltyCustomersCardGenerate(
    params: PkpassService.IncustControllersClientClientPkpassLoyaltyCustomersCardGenerateParams
  ): __Observable<null> {
    return this.incustControllersClientClientPkpassLoyaltyCustomersCardGenerateResponse(
      params
    ).pipe(__map((_r) => _r.body as null));
  }
}

module PkpassService {
  /**
   * Parameters for incustControllersClientClientPkpassLoyaltyCustomersCardGenerate
   */
  export interface IncustControllersClientClientPkpassLoyaltyCustomersCardGenerateParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Customers card ID
     */
    cardId: string;
  }
}

export { PkpassService };
