/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { UserExternalForm } from '../models/user-external-form';
@Injectable({
  providedIn: 'root'
})
class ExternalFormsService extends __BaseService {
  static readonly incustControllersClientClientLoyaltyExternalFormForUserGetPath =
    '/loyalty/{loyalty_id}/external_forms/{external_form_id}';
  static readonly incustControllersClientClientLoyaltyExternalFormForUserAddPath =
    '/loyalty/{loyalty_id}/external_forms/{external_form_id}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return external form template (prefilled or no) for user
   * @param params The `ExternalFormsService.IncustControllersClientClientLoyaltyExternalFormForUserGetParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `external_form_id`: External form ID
   *
   * - `prefilled`: Return prefilled (based on last filled form if exists) form
   *
   * @return User external form template info
   */
  incustControllersClientClientLoyaltyExternalFormForUserGetResponse(
    params: ExternalFormsService.IncustControllersClientClientLoyaltyExternalFormForUserGetParams
  ): __Observable<__StrictHttpResponse<UserExternalForm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.prefilled != null)
      __params = __params.set('prefilled', params.prefilled.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(
          String(params.loyaltyId)
        )}/external_forms/${encodeURIComponent(String(params.externalFormId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserExternalForm>;
      })
    );
  }
  /**
   * Return external form template (prefilled or no) for user
   * @param params The `ExternalFormsService.IncustControllersClientClientLoyaltyExternalFormForUserGetParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `external_form_id`: External form ID
   *
   * - `prefilled`: Return prefilled (based on last filled form if exists) form
   *
   * @return User external form template info
   */
  incustControllersClientClientLoyaltyExternalFormForUserGet(
    params: ExternalFormsService.IncustControllersClientClientLoyaltyExternalFormForUserGetParams
  ): __Observable<UserExternalForm> {
    return this.incustControllersClientClientLoyaltyExternalFormForUserGetResponse(
      params
    ).pipe(__map((_r) => _r.body as UserExternalForm));
  }

  /**
   * Add user filled external form
   * @param params The `ExternalFormsService.IncustControllersClientClientLoyaltyExternalFormForUserAddParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `external_form_id`: External form Id
   *
   * - `data`: Filled form data
   *
   * @return User external form info
   */
  incustControllersClientClientLoyaltyExternalFormForUserAddResponse(
    params: ExternalFormsService.IncustControllersClientClientLoyaltyExternalFormForUserAddParams
  ): __Observable<__StrictHttpResponse<UserExternalForm>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(
          String(params.loyaltyId)
        )}/external_forms/${encodeURIComponent(String(params.externalFormId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UserExternalForm>;
      })
    );
  }
  /**
   * Add user filled external form
   * @param params The `ExternalFormsService.IncustControllersClientClientLoyaltyExternalFormForUserAddParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `external_form_id`: External form Id
   *
   * - `data`: Filled form data
   *
   * @return User external form info
   */
  incustControllersClientClientLoyaltyExternalFormForUserAdd(
    params: ExternalFormsService.IncustControllersClientClientLoyaltyExternalFormForUserAddParams
  ): __Observable<UserExternalForm> {
    return this.incustControllersClientClientLoyaltyExternalFormForUserAddResponse(
      params
    ).pipe(__map((_r) => _r.body as UserExternalForm));
  }
}

module ExternalFormsService {
  /**
   * Parameters for incustControllersClientClientLoyaltyExternalFormForUserGet
   */
  export interface IncustControllersClientClientLoyaltyExternalFormForUserGetParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * External form ID
     */
    externalFormId: string;

    /**
     * Return prefilled (based on last filled form if exists) form
     */
    prefilled?: null | boolean;
  }

  /**
   * Parameters for incustControllersClientClientLoyaltyExternalFormForUserAdd
   */
  export interface IncustControllersClientClientLoyaltyExternalFormForUserAddParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * External form Id
     */
    externalFormId: string;

    /**
     * Filled form data
     */
    data: UserExternalForm;
  }
}

export { ExternalFormsService };
