/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Transaction } from '../models/transaction';
import { Check } from '../models/check';
import { TerminalSettings } from '../models/terminal-settings';
@Injectable({
  providedIn: 'root'
})
class CheckService extends __BaseService {
  static readonly incustControllersClientClientCheckAuthorizePaymentPath =
    '/check/authorize_payment/{terminal_id}';
  static readonly incustControllersClientClientCheckCancelPath =
    '/check/cancel/{terminal_id}';
  static readonly incustControllersClientClientCheckConfirmPath =
    '/check/confirm/{terminal_id}';
  static readonly incustControllersClientClientCheckFinalizePath =
    '/check/finalize/{terminal_id}';
  static readonly incustControllersClientClientCheckMakePath =
    '/check/make/{terminal_id}';
  static readonly incustControllersClientClientCheckMakeByRulesPath =
    '/check/make/{terminal_id}/{rules_type}';
  static readonly incustControllersClientClientCheckProcessPath =
    '/check/process/{terminal_id}';
  static readonly incustControllersClientClientCheckProcessByRulesPath =
    '/check/process/{terminal_id}/{rules_type}';
  static readonly incustControllersClientClientCheckReservePath =
    '/check/reserve/{terminal_id}';
  static readonly incustControllersClientClientCheckWritePath =
    '/check/write/{terminal_id}';
  static readonly incustControllersClientClientTerminalSettingsPath =
    '/terminal/{terminal_id}/settings';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Authorize payment by check data
   * @param params The `CheckService.IncustControllersClientClientCheckAuthorizePaymentParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckAuthorizePaymentResponse(
    params: CheckService.IncustControllersClientClientCheckAuthorizePaymentParams
  ): __Observable<__StrictHttpResponse<Transaction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/authorize_payment/${encodeURIComponent(
          String(params.terminalId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transaction>;
      })
    );
  }
  /**
   * Authorize payment by check data
   * @param params The `CheckService.IncustControllersClientClientCheckAuthorizePaymentParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckAuthorizePayment(
    params: CheckService.IncustControllersClientClientCheckAuthorizePaymentParams
  ): __Observable<Transaction> {
    return this.incustControllersClientClientCheckAuthorizePaymentResponse(
      params
    ).pipe(__map((_r) => _r.body as Transaction));
  }

  /**
   * Cancel transaction
   * @param params The `CheckService.IncustControllersClientClientCheckCancelParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Transaction object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckCancelResponse(
    params: CheckService.IncustControllersClientClientCheckCancelParams
  ): __Observable<__StrictHttpResponse<Transaction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/cancel/${encodeURIComponent(String(params.terminalId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transaction>;
      })
    );
  }
  /**
   * Cancel transaction
   * @param params The `CheckService.IncustControllersClientClientCheckCancelParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Transaction object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckCancel(
    params: CheckService.IncustControllersClientClientCheckCancelParams
  ): __Observable<Transaction> {
    return this.incustControllersClientClientCheckCancelResponse(params).pipe(
      __map((_r) => _r.body as Transaction)
    );
  }

  /**
   * Make transaction by check data with finalization
   * @param params The `CheckService.IncustControllersClientClientCheckConfirmParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckConfirmResponse(
    params: CheckService.IncustControllersClientClientCheckConfirmParams
  ): __Observable<__StrictHttpResponse<Transaction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/confirm/${encodeURIComponent(String(params.terminalId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transaction>;
      })
    );
  }
  /**
   * Make transaction by check data with finalization
   * @param params The `CheckService.IncustControllersClientClientCheckConfirmParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckConfirm(
    params: CheckService.IncustControllersClientClientCheckConfirmParams
  ): __Observable<Transaction> {
    return this.incustControllersClientClientCheckConfirmResponse(params).pipe(
      __map((_r) => _r.body as Transaction)
    );
  }

  /**
   * Finalize transaction
   * @param params The `CheckService.IncustControllersClientClientCheckFinalizeParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Transaction object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckFinalizeResponse(
    params: CheckService.IncustControllersClientClientCheckFinalizeParams
  ): __Observable<__StrictHttpResponse<Transaction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/finalize/${encodeURIComponent(String(params.terminalId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transaction>;
      })
    );
  }
  /**
   * Finalize transaction
   * @param params The `CheckService.IncustControllersClientClientCheckFinalizeParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Transaction object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckFinalize(
    params: CheckService.IncustControllersClientClientCheckFinalizeParams
  ): __Observable<Transaction> {
    return this.incustControllersClientClientCheckFinalizeResponse(params).pipe(
      __map((_r) => _r.body as Transaction)
    );
  }

  /**
   * Process check data, modify and confirm check - implement loyalty rules
   * @param params The `CheckService.IncustControllersClientClientCheckMakeParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckMakeResponse(
    params: CheckService.IncustControllersClientClientCheckMakeParams
  ): __Observable<__StrictHttpResponse<Transaction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/make/${encodeURIComponent(String(params.terminalId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transaction>;
      })
    );
  }
  /**
   * Process check data, modify and confirm check - implement loyalty rules
   * @param params The `CheckService.IncustControllersClientClientCheckMakeParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckMake(
    params: CheckService.IncustControllersClientClientCheckMakeParams
  ): __Observable<Transaction> {
    return this.incustControllersClientClientCheckMakeResponse(params).pipe(
      __map((_r) => _r.body as Transaction)
    );
  }

  /**
   * Process check data, modify and confirm check - implement loyalty rules by selected type
   * @param params The `CheckService.IncustControllersClientClientCheckMakeByRulesParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `rules_type`: Process check rules type
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckMakeByRulesResponse(
    params: CheckService.IncustControllersClientClientCheckMakeByRulesParams
  ): __Observable<__StrictHttpResponse<Transaction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/make/${encodeURIComponent(
          String(params.terminalId)
        )}/${encodeURIComponent(String(params.rulesType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transaction>;
      })
    );
  }
  /**
   * Process check data, modify and confirm check - implement loyalty rules by selected type
   * @param params The `CheckService.IncustControllersClientClientCheckMakeByRulesParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `rules_type`: Process check rules type
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckMakeByRules(
    params: CheckService.IncustControllersClientClientCheckMakeByRulesParams
  ): __Observable<Transaction> {
    return this.incustControllersClientClientCheckMakeByRulesResponse(
      params
    ).pipe(__map((_r) => _r.body as Transaction));
  }

  /**
   * Process check data, modify check - implement loyalty rules
   * @param params The `CheckService.IncustControllersClientClientCheckProcessParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Check object
   */
  incustControllersClientClientCheckProcessResponse(
    params: CheckService.IncustControllersClientClientCheckProcessParams
  ): __Observable<__StrictHttpResponse<Check>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/process/${encodeURIComponent(String(params.terminalId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Check>;
      })
    );
  }
  /**
   * Process check data, modify check - implement loyalty rules
   * @param params The `CheckService.IncustControllersClientClientCheckProcessParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Check object
   */
  incustControllersClientClientCheckProcess(
    params: CheckService.IncustControllersClientClientCheckProcessParams
  ): __Observable<Check> {
    return this.incustControllersClientClientCheckProcessResponse(params).pipe(
      __map((_r) => _r.body as Check)
    );
  }

  /**
   * Process check data, modify check - implement loyalty rules by selected type
   * @param params The `CheckService.IncustControllersClientClientCheckProcessByRulesParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `rules_type`: Process check rules type
   *
   * - `data`: Check object
   *
   * @return Check object
   */
  incustControllersClientClientCheckProcessByRulesResponse(
    params: CheckService.IncustControllersClientClientCheckProcessByRulesParams
  ): __Observable<__StrictHttpResponse<Check>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/process/${encodeURIComponent(
          String(params.terminalId)
        )}/${encodeURIComponent(String(params.rulesType))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Check>;
      })
    );
  }
  /**
   * Process check data, modify check - implement loyalty rules by selected type
   * @param params The `CheckService.IncustControllersClientClientCheckProcessByRulesParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `rules_type`: Process check rules type
   *
   * - `data`: Check object
   *
   * @return Check object
   */
  incustControllersClientClientCheckProcessByRules(
    params: CheckService.IncustControllersClientClientCheckProcessByRulesParams
  ): __Observable<Check> {
    return this.incustControllersClientClientCheckProcessByRulesResponse(
      params
    ).pipe(__map((_r) => _r.body as Check));
  }

  /**
   * Reserve by check data
   * @param params The `CheckService.IncustControllersClientClientCheckReserveParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckReserveResponse(
    params: CheckService.IncustControllersClientClientCheckReserveParams
  ): __Observable<__StrictHttpResponse<Transaction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/reserve/${encodeURIComponent(String(params.terminalId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transaction>;
      })
    );
  }
  /**
   * Reserve by check data
   * @param params The `CheckService.IncustControllersClientClientCheckReserveParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckReserve(
    params: CheckService.IncustControllersClientClientCheckReserveParams
  ): __Observable<Transaction> {
    return this.incustControllersClientClientCheckReserveResponse(params).pipe(
      __map((_r) => _r.body as Transaction)
    );
  }

  /**
   * Make transaction by check data without finalization
   * @param params The `CheckService.IncustControllersClientClientCheckWriteParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckWriteResponse(
    params: CheckService.IncustControllersClientClientCheckWriteParams
  ): __Observable<__StrictHttpResponse<Transaction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/check/write/${encodeURIComponent(String(params.terminalId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transaction>;
      })
    );
  }
  /**
   * Make transaction by check data without finalization
   * @param params The `CheckService.IncustControllersClientClientCheckWriteParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `data`: Check object
   *
   * @return Transaction object
   */
  incustControllersClientClientCheckWrite(
    params: CheckService.IncustControllersClientClientCheckWriteParams
  ): __Observable<Transaction> {
    return this.incustControllersClientClientCheckWriteResponse(params).pipe(
      __map((_r) => _r.body as Transaction)
    );
  }

  /**
   * Returns terminal settings
   * @param params The `CheckService.IncustControllersClientClientTerminalSettingsParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `fuel_info_taxes_values`: Return taxes for price in fuel info (0/1)
   *
   * - `fuel_info_recommended_values_presets`: Return recommended values presets in fuel info (0/1)
   *
   * - `fuel_info_prices_values_scope`: Scope for return available prices (not only base) in fuel info
   *
   * - `fuel_info_prices_values`: Return available prices by scope (not only base) in fuel info (0/1)
   *
   * - `fuel_info_price_customers_account_info_extended`: Include account customers limits data (only if fuel_info_price_customers_account_info is 1)
   *
   * - `fuel_info_price_customers_account_info`: Include account customers data in prices (0/1)
   *
   * - `fuel_info_margins_values`: Return margins for price in fuel info (0/1)
   *
   * - `fuel_info`: Return fuel info (0/1)
   *
   * - `equipments_info`: Return equipments info (0/1)
   *
   * - `coupons`: Return coupons info (0/1)
   *
   * - `card_categories`: Return card categories info (0/1)
   *
   * - `bonuses`: Return bonuses and accounts info (0/1)
   *
   * @return Terminal settings
   */
  incustControllersClientClientTerminalSettingsResponse(
    params: CheckService.IncustControllersClientClientTerminalSettingsParams
  ): __Observable<__StrictHttpResponse<TerminalSettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.fuelInfoTaxesValues != null)
      __params = __params.set(
        'fuel_info_taxes_values',
        params.fuelInfoTaxesValues.toString()
      );
    if (params.fuelInfoRecommendedValuesPresets != null)
      __params = __params.set(
        'fuel_info_recommended_values_presets',
        params.fuelInfoRecommendedValuesPresets.toString()
      );
    if (params.fuelInfoPricesValuesScope != null)
      __params = __params.set(
        'fuel_info_prices_values_scope',
        params.fuelInfoPricesValuesScope.toString()
      );
    if (params.fuelInfoPricesValues != null)
      __params = __params.set(
        'fuel_info_prices_values',
        params.fuelInfoPricesValues.toString()
      );
    if (params.fuelInfoPriceCustomersAccountInfoExtended != null)
      __params = __params.set(
        'fuel_info_price_customers_account_info_extended',
        params.fuelInfoPriceCustomersAccountInfoExtended.toString()
      );
    if (params.fuelInfoPriceCustomersAccountInfo != null)
      __params = __params.set(
        'fuel_info_price_customers_account_info',
        params.fuelInfoPriceCustomersAccountInfo.toString()
      );
    if (params.fuelInfoMarginsValues != null)
      __params = __params.set(
        'fuel_info_margins_values',
        params.fuelInfoMarginsValues.toString()
      );
    if (params.fuelInfo != null)
      __params = __params.set('fuel_info', params.fuelInfo.toString());
    if (params.equipmentsInfo != null)
      __params = __params.set(
        'equipments_info',
        params.equipmentsInfo.toString()
      );
    if (params.coupons != null)
      __params = __params.set('coupons', params.coupons.toString());
    if (params.cardCategories != null)
      __params = __params.set(
        'card_categories',
        params.cardCategories.toString()
      );
    if (params.bonuses != null)
      __params = __params.set('bonuses', params.bonuses.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/terminal/${encodeURIComponent(String(params.terminalId))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TerminalSettings>;
      })
    );
  }
  /**
   * Returns terminal settings
   * @param params The `CheckService.IncustControllersClientClientTerminalSettingsParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `fuel_info_taxes_values`: Return taxes for price in fuel info (0/1)
   *
   * - `fuel_info_recommended_values_presets`: Return recommended values presets in fuel info (0/1)
   *
   * - `fuel_info_prices_values_scope`: Scope for return available prices (not only base) in fuel info
   *
   * - `fuel_info_prices_values`: Return available prices by scope (not only base) in fuel info (0/1)
   *
   * - `fuel_info_price_customers_account_info_extended`: Include account customers limits data (only if fuel_info_price_customers_account_info is 1)
   *
   * - `fuel_info_price_customers_account_info`: Include account customers data in prices (0/1)
   *
   * - `fuel_info_margins_values`: Return margins for price in fuel info (0/1)
   *
   * - `fuel_info`: Return fuel info (0/1)
   *
   * - `equipments_info`: Return equipments info (0/1)
   *
   * - `coupons`: Return coupons info (0/1)
   *
   * - `card_categories`: Return card categories info (0/1)
   *
   * - `bonuses`: Return bonuses and accounts info (0/1)
   *
   * @return Terminal settings
   */
  incustControllersClientClientTerminalSettings(
    params: CheckService.IncustControllersClientClientTerminalSettingsParams
  ): __Observable<TerminalSettings> {
    return this.incustControllersClientClientTerminalSettingsResponse(
      params
    ).pipe(__map((_r) => _r.body as TerminalSettings));
  }
}

module CheckService {
  /**
   * Parameters for incustControllersClientClientCheckAuthorizePayment
   */
  export interface IncustControllersClientClientCheckAuthorizePaymentParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Check object
     */
    data: Check;
  }

  /**
   * Parameters for incustControllersClientClientCheckCancel
   */
  export interface IncustControllersClientClientCheckCancelParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Transaction object
     */
    data: { comment?: string; id: string };
  }

  /**
   * Parameters for incustControllersClientClientCheckConfirm
   */
  export interface IncustControllersClientClientCheckConfirmParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Check object
     */
    data: Check;
  }

  /**
   * Parameters for incustControllersClientClientCheckFinalize
   */
  export interface IncustControllersClientClientCheckFinalizeParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Transaction object
     */
    data: { comment?: string; id: string };
  }

  /**
   * Parameters for incustControllersClientClientCheckMake
   */
  export interface IncustControllersClientClientCheckMakeParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Check object
     */
    data: Check;
  }

  /**
   * Parameters for incustControllersClientClientCheckMakeByRules
   */
  export interface IncustControllersClientClientCheckMakeByRulesParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Process check rules type
     */
    rulesType: 'by-all-rules' | 'by-charge-only-rules' | 'without-rules';

    /**
     * Check object
     */
    data: Check;
  }

  /**
   * Parameters for incustControllersClientClientCheckProcess
   */
  export interface IncustControllersClientClientCheckProcessParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Check object
     */
    data: Check;
  }

  /**
   * Parameters for incustControllersClientClientCheckProcessByRules
   */
  export interface IncustControllersClientClientCheckProcessByRulesParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Process check rules type
     */
    rulesType: 'by-all-rules' | 'by-charge-only-rules' | 'without-rules';

    /**
     * Check object
     */
    data: Check;
  }

  /**
   * Parameters for incustControllersClientClientCheckReserve
   */
  export interface IncustControllersClientClientCheckReserveParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Check object
     */
    data: Check;
  }

  /**
   * Parameters for incustControllersClientClientCheckWrite
   */
  export interface IncustControllersClientClientCheckWriteParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Check object
     */
    data: Check;
  }

  /**
   * Parameters for incustControllersClientClientTerminalSettings
   */
  export interface IncustControllersClientClientTerminalSettingsParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Return taxes for price in fuel info (0/1)
     */
    fuelInfoTaxesValues?: 0 | 1;

    /**
     * Return recommended values presets in fuel info (0/1)
     */
    fuelInfoRecommendedValuesPresets?: 0 | 1;

    /**
     * Scope for return available prices (not only base) in fuel info
     */
    fuelInfoPricesValuesScope?: 'all' | 'applicable' | 'minimal';

    /**
     * Return available prices by scope (not only base) in fuel info (0/1)
     */
    fuelInfoPricesValues?: 0 | 1;

    /**
     * Include account customers limits data (only if fuel_info_price_customers_account_info is 1)
     */
    fuelInfoPriceCustomersAccountInfoExtended?: 0 | 1;

    /**
     * Include account customers data in prices (0/1)
     */
    fuelInfoPriceCustomersAccountInfo?: 0 | 1;

    /**
     * Return margins for price in fuel info (0/1)
     */
    fuelInfoMarginsValues?: 0 | 1;

    /**
     * Return fuel info (0/1)
     */
    fuelInfo?: 0 | 1;

    /**
     * Return equipments info (0/1)
     */
    equipmentsInfo?: 0 | 1;

    /**
     * Return coupons info (0/1)
     */
    coupons?: 0 | 1;

    /**
     * Return card categories info (0/1)
     */
    cardCategories?: 0 | 1;

    /**
     * Return bonuses and accounts info (0/1)
     */
    bonuses?: 0 | 1;
  }
}

export { CheckService };
