@if (invitation$ | async; as invitation) {
    <div class="invitation">
        <div class="container">
            <div class="invitation__holder">
                <a href="{{environment.CLIENT_URL}}/hint/show/{{ invitation.code }}">
                    <img src="{{ invitation.loyalty?.photos?.[0]?.url || '' }}"
                         alt="{{ invitation.loyalty?.public_title }}"
                         title="{{ invitation.loyalty?.public_title  }}"
                         class="coupon-picture"/>
                    <h1>
                        {{ invitation.loyalty?.public_title  }}
                    </h1>
                    <p [innerHTML]="invitation.loyalty?.public_description">
                    </p>
                </a>
            </div>
        </div>
    </div>
}
