/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GiftCard } from '../models/gift-card';
@Injectable({
  providedIn: 'root'
})
class GiftCardService extends __BaseService {
  static readonly incustControllersClientClientGiftCardInfoPath =
    '/gift_cards/card/{code}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Gift Card info
   * @param code Gift Card CODE
   * @return Gift Card data
   */
  incustControllersClientClientGiftCardInfoResponse(
    code: string
  ): __Observable<__StrictHttpResponse<GiftCard>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/gift_cards/card/${encodeURIComponent(String(code))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GiftCard>;
      })
    );
  }
  /**
   * Gift Card info
   * @param code Gift Card CODE
   * @return Gift Card data
   */
  incustControllersClientClientGiftCardInfo(
    code: string
  ): __Observable<GiftCard> {
    return this.incustControllersClientClientGiftCardInfoResponse(code).pipe(
      __map((_r) => _r.body as GiftCard)
    );
  }
}

module GiftCardService {}

export { GiftCardService };
