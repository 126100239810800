/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Message } from '../models/message';
import { MessageResponse } from '../models/message-response';
@Injectable({
  providedIn: 'root'
})
class MessageService extends __BaseService {
  static readonly incustControllersClientClientMessagesPath = '/message';
  static readonly incustControllersClientClientAllMessagesReadSetPath =
    '/message';
  static readonly incustControllersClientClientMessageSendPath = '/message';
  static readonly incustControllersClientClientMessagesUnreadCountPath =
    '/message/unread_count';
  static readonly incustControllersClientClientMessageDeletePath =
    '/message/{message_id}';
  static readonly incustControllersClientClientMessagePath =
    '/message/{message_id}';
  static readonly incustControllersClientClientMessageReadSetPath =
    '/message/{message_id}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Messages for client
   * @param params The `MessageService.IncustControllersClientClientMessagesParams` containing the following parameters:
   *
   * - `page`: List page number
   *
   * - `count`: Messages count in page
   *
   * - `attachment_type`: Type of attachment filter
   *
   * @return Messages array
   */
  incustControllersClientClientMessagesResponse(
    params: MessageService.IncustControllersClientClientMessagesParams
  ): __Observable<__StrictHttpResponse<Array<Message>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null)
      __params = __params.set('page', params.page.toString());
    if (params.count != null)
      __params = __params.set('count', params.count.toString());
    if (params.attachmentType != null)
      __params = __params.set(
        'attachment_type',
        params.attachmentType.toString()
      );
    let req = new HttpRequest<any>('GET', this.rootUrl + `/message`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Message>>;
      })
    );
  }
  /**
   * Messages for client
   * @param params The `MessageService.IncustControllersClientClientMessagesParams` containing the following parameters:
   *
   * - `page`: List page number
   *
   * - `count`: Messages count in page
   *
   * - `attachment_type`: Type of attachment filter
   *
   * @return Messages array
   */
  incustControllersClientClientMessages(
    params: MessageService.IncustControllersClientClientMessagesParams
  ): __Observable<Array<Message>> {
    return this.incustControllersClientClientMessagesResponse(params).pipe(
      __map((_r) => _r.body as Array<Message>)
    );
  }

  /**
   * Set message read status for all user messages
   * @return Ok
   */
  incustControllersClientClientAllMessagesReadSetResponse(): __Observable<
    __StrictHttpResponse<MessageResponse>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('PATCH', this.rootUrl + `/message`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Set message read status for all user messages
   * @return Ok
   */
  incustControllersClientClientAllMessagesReadSet(): __Observable<MessageResponse> {
    return this.incustControllersClientClientAllMessagesReadSetResponse().pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }

  /**
   * Send message
   * @param data Message data
   * @return Message object
   */
  incustControllersClientClientMessageSendResponse(data: {
    message: string;
    to?: string;
  }): __Observable<__StrictHttpResponse<Message>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/message`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Message>;
      })
    );
  }
  /**
   * Send message
   * @param data Message data
   * @return Message object
   */
  incustControllersClientClientMessageSend(data: {
    message: string;
    to?: string;
  }): __Observable<Message> {
    return this.incustControllersClientClientMessageSendResponse(data).pipe(
      __map((_r) => _r.body as Message)
    );
  }

  /**
   * Count of unreaded messages for client
   * @return Count in code
   */
  incustControllersClientClientMessagesUnreadCountResponse(): __Observable<
    __StrictHttpResponse<MessageResponse>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/message/unread_count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Count of unreaded messages for client
   * @return Count in code
   */
  incustControllersClientClientMessagesUnreadCount(): __Observable<MessageResponse> {
    return this.incustControllersClientClientMessagesUnreadCountResponse().pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }

  /**
   * Delete message
   * @param message_id Message ID
   * @return Ok
   */
  incustControllersClientClientMessageDeleteResponse(
    messageId: string
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/message/${encodeURIComponent(String(messageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Delete message
   * @param message_id Message ID
   * @return Ok
   */
  incustControllersClientClientMessageDelete(
    messageId: string
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientMessageDeleteResponse(
      messageId
    ).pipe(__map((_r) => _r.body as MessageResponse));
  }

  /**
   * Messages for client by ID
   * @param message_id Message ID
   * @return Message object
   */
  incustControllersClientClientMessageResponse(
    messageId: string
  ): __Observable<__StrictHttpResponse<Message>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/message/${encodeURIComponent(String(messageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Message>;
      })
    );
  }
  /**
   * Messages for client by ID
   * @param message_id Message ID
   * @return Message object
   */
  incustControllersClientClientMessage(
    messageId: string
  ): __Observable<Message> {
    return this.incustControllersClientClientMessageResponse(messageId).pipe(
      __map((_r) => _r.body as Message)
    );
  }

  /**
   * Set message read status
   * @param message_id Message ID
   * @return Ok
   */
  incustControllersClientClientMessageReadSetResponse(
    messageId: string
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/message/${encodeURIComponent(String(messageId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Set message read status
   * @param message_id Message ID
   * @return Ok
   */
  incustControllersClientClientMessageReadSet(
    messageId: string
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientMessageReadSetResponse(
      messageId
    ).pipe(__map((_r) => _r.body as MessageResponse));
  }
}

module MessageService {
  /**
   * Parameters for incustControllersClientClientMessages
   */
  export interface IncustControllersClientClientMessagesParams {
    /**
     * List page number
     */
    page?: number;

    /**
     * Messages count in page
     */
    count?: number;

    /**
     * Type of attachment filter
     */
    attachmentType?:
      | 'image'
      | 'coupon'
      | 'news'
      | 'payment'
      | 'feedback-source'
      | 'online-store-order';
  }
}

export { MessageService };
