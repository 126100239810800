/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TransactionResponse } from '../models/transaction-response';
import { Transaction } from '../models/transaction';
import { TransactionReceipt } from '../models/transaction-receipt';
@Injectable({
  providedIn: 'root'
})
class TransactionService extends __BaseService {
  static readonly incustControllersClientClientTransactionsPath =
    '/transactions';
  static readonly incustControllersClientClientGetTransactionPath =
    '/transactions/{transaction_id}';
  static readonly incustControllersClientClientGetTransactionReceiptPath =
    '/transactions/{transaction_id}/receipt';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return transactions list
   * @param params The `TransactionService.IncustControllersClientClientTransactionsParams` containing the following parameters:
   *
   * - `skip_totals`: Do not calculate totals values
   *
   * - `page`: List page number
   *
   * - `loyalty`: Loyalty ID
   *
   * - `count`: Transactions count in page
   *
   * @return Transaction response object
   */
  incustControllersClientClientTransactionsResponse(
    params: TransactionService.IncustControllersClientClientTransactionsParams
  ): __Observable<__StrictHttpResponse<TransactionResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.skipTotals != null)
      __params = __params.set('skip_totals', params.skipTotals.toString());
    if (params.page != null)
      __params = __params.set('page', params.page.toString());
    if (params.loyalty != null)
      __params = __params.set('loyalty', params.loyalty.toString());
    if (params.count != null)
      __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/transactions`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransactionResponse>;
      })
    );
  }
  /**
   * Return transactions list
   * @param params The `TransactionService.IncustControllersClientClientTransactionsParams` containing the following parameters:
   *
   * - `skip_totals`: Do not calculate totals values
   *
   * - `page`: List page number
   *
   * - `loyalty`: Loyalty ID
   *
   * - `count`: Transactions count in page
   *
   * @return Transaction response object
   */
  incustControllersClientClientTransactions(
    params: TransactionService.IncustControllersClientClientTransactionsParams
  ): __Observable<TransactionResponse> {
    return this.incustControllersClientClientTransactionsResponse(params).pipe(
      __map((_r) => _r.body as TransactionResponse)
    );
  }

  /**
   * Return transaction information
   * @param transaction_id Transaction ID
   * @return Transaction object
   */
  incustControllersClientClientGetTransactionResponse(
    transactionId: string
  ): __Observable<__StrictHttpResponse<Transaction>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/transactions/${encodeURIComponent(String(transactionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transaction>;
      })
    );
  }
  /**
   * Return transaction information
   * @param transaction_id Transaction ID
   * @return Transaction object
   */
  incustControllersClientClientGetTransaction(
    transactionId: string
  ): __Observable<Transaction> {
    return this.incustControllersClientClientGetTransactionResponse(
      transactionId
    ).pipe(__map((_r) => _r.body as Transaction));
  }

  /**
   * Return transaction receipt
   * @param transaction_id Transaction ID
   * @return Transaction object
   */
  incustControllersClientClientGetTransactionReceiptResponse(
    transactionId: string
  ): __Observable<__StrictHttpResponse<TransactionReceipt>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/transactions/${encodeURIComponent(String(transactionId))}/receipt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TransactionReceipt>;
      })
    );
  }
  /**
   * Return transaction receipt
   * @param transaction_id Transaction ID
   * @return Transaction object
   */
  incustControllersClientClientGetTransactionReceipt(
    transactionId: string
  ): __Observable<TransactionReceipt> {
    return this.incustControllersClientClientGetTransactionReceiptResponse(
      transactionId
    ).pipe(__map((_r) => _r.body as TransactionReceipt));
  }
}

module TransactionService {
  /**
   * Parameters for incustControllersClientClientTransactions
   */
  export interface IncustControllersClientClientTransactionsParams {
    /**
     * Do not calculate totals values
     */
    skipTotals?: boolean;

    /**
     * List page number
     */
    page?: number;

    /**
     * Loyalty ID
     */
    loyalty?: string;

    /**
     * Transactions count in page
     */
    count?: number;
  }
}

export { TransactionService };
