/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PurchaseOrder } from '../models/purchase-order';
@Injectable({
  providedIn: 'root'
})
class PurchaseOrderService extends __BaseService {
  static readonly incustControllersClientClientCreateOrderPath =
    '/orders/create';
  static readonly incustControllersClientClientGetOrderPath =
    '/orders/{business_id}/{id}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Create purchase order
   * @param data Purchase order object
   * @return Purchase order object
   */
  incustControllersClientClientCreateOrderResponse(
    data: PurchaseOrder
  ): __Observable<__StrictHttpResponse<PurchaseOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/orders/create`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PurchaseOrder>;
      })
    );
  }
  /**
   * Create purchase order
   * @param data Purchase order object
   * @return Purchase order object
   */
  incustControllersClientClientCreateOrder(
    data: PurchaseOrder
  ): __Observable<PurchaseOrder> {
    return this.incustControllersClientClientCreateOrderResponse(data).pipe(
      __map((_r) => _r.body as PurchaseOrder)
    );
  }

  /**
   * Get purchase order
   * @param params The `PurchaseOrderService.IncustControllersClientClientGetOrderParams` containing the following parameters:
   *
   * - `id`: Purchase order ID
   *
   * - `business_id`: Business ID
   *
   * @return Purchase order object
   */
  incustControllersClientClientGetOrderResponse(
    params: PurchaseOrderService.IncustControllersClientClientGetOrderParams
  ): __Observable<__StrictHttpResponse<PurchaseOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/orders/${encodeURIComponent(
          String(params.businessId)
        )}/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PurchaseOrder>;
      })
    );
  }
  /**
   * Get purchase order
   * @param params The `PurchaseOrderService.IncustControllersClientClientGetOrderParams` containing the following parameters:
   *
   * - `id`: Purchase order ID
   *
   * - `business_id`: Business ID
   *
   * @return Purchase order object
   */
  incustControllersClientClientGetOrder(
    params: PurchaseOrderService.IncustControllersClientClientGetOrderParams
  ): __Observable<PurchaseOrder> {
    return this.incustControllersClientClientGetOrderResponse(params).pipe(
      __map((_r) => _r.body as PurchaseOrder)
    );
  }
}

module PurchaseOrderService {
  /**
   * Parameters for incustControllersClientClientGetOrder
   */
  export interface IncustControllersClientClientGetOrderParams {
    /**
     * Purchase order ID
     */
    id: string;

    /**
     * Business ID
     */
    businessId: string;
  }
}

export { PurchaseOrderService };
