/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MessageResponse } from '../models/message-response';
import { PushTokenData } from '../models/push-token-data';
@Injectable({
  providedIn: 'root'
})
class PushService extends __BaseService {
  static readonly incustControllersClientClientPushTokenDelPath = '/push_token';
  static readonly incustControllersClientClientPushTokenSetPath = '/push_token';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Delete push token
   * @param params The `PushService.IncustControllersClientClientPushTokenDelParams` containing the following parameters:
   *
   * - `token`: Push FCM token
   *
   * - `platform`: Type of mobile device mobile client platform ('android', 'ios', 'windows')
   *
   * @return Ok
   */
  incustControllersClientClientPushTokenDelResponse(
    params: PushService.IncustControllersClientClientPushTokenDelParams
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null)
      __params = __params.set('token', params.token.toString());
    if (params.platform != null)
      __params = __params.set('platform', params.platform.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/push_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Delete push token
   * @param params The `PushService.IncustControllersClientClientPushTokenDelParams` containing the following parameters:
   *
   * - `token`: Push FCM token
   *
   * - `platform`: Type of mobile device mobile client platform ('android', 'ios', 'windows')
   *
   * @return Ok
   */
  incustControllersClientClientPushTokenDel(
    params: PushService.IncustControllersClientClientPushTokenDelParams
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientPushTokenDelResponse(params).pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }

  /**
   * Register new push token
   * @param data Push token data
   * @return Ok
   */
  incustControllersClientClientPushTokenSetResponse(
    data: PushTokenData
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/push_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Register new push token
   * @param data Push token data
   * @return Ok
   */
  incustControllersClientClientPushTokenSet(
    data: PushTokenData
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientPushTokenSetResponse(data).pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }
}

module PushService {
  /**
   * Parameters for incustControllersClientClientPushTokenDel
   */
  export interface IncustControllersClientClientPushTokenDelParams {
    /**
     * Push FCM token
     */
    token: string;

    /**
     * Type of mobile device mobile client platform ('android', 'ios', 'windows')
     */
    platform: 'android' | 'ios' | 'windows';
  }
}

export { PushService };
