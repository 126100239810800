/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GoodsCategory } from '../models/goods-category';
import { Goods } from '../models/goods';
import { CustomerCart } from '../models/customer-cart';
import { DeliveryType } from '../models/delivery-type';
import { OrdersListResult } from '../models/orders-list-result';
import { Order } from '../models/order';
@Injectable({
  providedIn: 'root'
})
class OnlineStoreService extends __BaseService {
  static readonly incustControllersClientClientGetGoodsCategoriesItemsPath =
    '/goods/categories/{loyalty_id}';
  static readonly incustControllersClientClientGetGoodsItemsPath =
    '/goods/{loyalty_id}';
  static readonly incustControllersClientClientUserCartDeletePath =
    '/loyalty/{loyalty_id}/cart';
  static readonly incustControllersClientClientUserCartGetPath =
    '/loyalty/{loyalty_id}/cart';
  static readonly incustControllersClientClientUserCartModifyPath =
    '/loyalty/{loyalty_id}/cart';
  static readonly incustControllersClientClientLoyaltyDeliveriesGetPath =
    '/loyalty/{loyalty_id}/deliveries';
  static readonly incustControllersClientClientOnlineStoreLoyaltyOrdersGetPath =
    '/online_store/loyalty/{loyalty_id}/orders';
  static readonly incustControllersClientClientOnlineStoreLoyaltyOrderAddPath =
    '/online_store/loyalty/{loyalty_id}/orders';
  static readonly incustControllersClientClientOnlineStoreOrdersGetPath =
    '/online_store/orders';
  static readonly incustControllersClientClientOnlineStoreOrderGetPath =
    '/online_store/orders/{order_id}';
  static readonly incustControllersClientClientOnlineStoreOrderCancelPath =
    '/online_store/orders/{order_id}/cancel';
  static readonly incustControllersClientClientOnlineStorePosOrdersGetPath =
    '/online_store/pos/{pos_id}/orders';
  static readonly incustControllersClientClientOnlineStorePosOrderAddPath =
    '/online_store/pos/{pos_id}/orders';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return list of goods categories available in online store
   * @param params The `OnlineStoreService.IncustControllersClientClientGetGoodsCategoriesItemsParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `parent_id`: Parent category ID or 'root' for root level categories. If parameter is not set return ALL categories.
   *
   * @return Goods category data array
   */
  incustControllersClientClientGetGoodsCategoriesItemsResponse(
    params: OnlineStoreService.IncustControllersClientClientGetGoodsCategoriesItemsParams
  ): __Observable<__StrictHttpResponse<GoodsCategory>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.parentId != null)
      __params = __params.set('parent_id', params.parentId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/goods/categories/${encodeURIComponent(String(params.loyaltyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GoodsCategory>;
      })
    );
  }
  /**
   * Return list of goods categories available in online store
   * @param params The `OnlineStoreService.IncustControllersClientClientGetGoodsCategoriesItemsParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `parent_id`: Parent category ID or 'root' for root level categories. If parameter is not set return ALL categories.
   *
   * @return Goods category data array
   */
  incustControllersClientClientGetGoodsCategoriesItems(
    params: OnlineStoreService.IncustControllersClientClientGetGoodsCategoriesItemsParams
  ): __Observable<GoodsCategory> {
    return this.incustControllersClientClientGetGoodsCategoriesItemsResponse(
      params
    ).pipe(__map((_r) => _r.body as GoodsCategory));
  }

  /**
   * Return list of goods available in online store
   * @param params The `OnlineStoreService.IncustControllersClientClientGetGoodsItemsParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `price_customers_account_info_extended`: Include account customers limits data (only if price_customers_account_info is true)
   *
   * - `price_customers_account_info`: Include account customers data in prices
   *
   * - `parent_id`: Parent category ID or 'root' for root level items. If parameter is not set return ALL goods items.
   *
   * @return Goods data array
   */
  incustControllersClientClientGetGoodsItemsResponse(
    params: OnlineStoreService.IncustControllersClientClientGetGoodsItemsParams
  ): __Observable<__StrictHttpResponse<Goods>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.priceCustomersAccountInfoExtended != null)
      __params = __params.set(
        'price_customers_account_info_extended',
        params.priceCustomersAccountInfoExtended.toString()
      );
    if (params.priceCustomersAccountInfo != null)
      __params = __params.set(
        'price_customers_account_info',
        params.priceCustomersAccountInfo.toString()
      );
    if (params.parentId != null)
      __params = __params.set('parent_id', params.parentId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/goods/${encodeURIComponent(String(params.loyaltyId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Goods>;
      })
    );
  }
  /**
   * Return list of goods available in online store
   * @param params The `OnlineStoreService.IncustControllersClientClientGetGoodsItemsParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `price_customers_account_info_extended`: Include account customers limits data (only if price_customers_account_info is true)
   *
   * - `price_customers_account_info`: Include account customers data in prices
   *
   * - `parent_id`: Parent category ID or 'root' for root level items. If parameter is not set return ALL goods items.
   *
   * @return Goods data array
   */
  incustControllersClientClientGetGoodsItems(
    params: OnlineStoreService.IncustControllersClientClientGetGoodsItemsParams
  ): __Observable<Goods> {
    return this.incustControllersClientClientGetGoodsItemsResponse(params).pipe(
      __map((_r) => _r.body as Goods)
    );
  }

  /**
   * Clear user's cart
   * @param loyalty_id Loyalty ID
   * @return Customer cart object
   */
  incustControllersClientClientUserCartDeleteResponse(
    loyaltyId: string
  ): __Observable<__StrictHttpResponse<CustomerCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/loyalty/${encodeURIComponent(String(loyaltyId))}/cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerCart>;
      })
    );
  }
  /**
   * Clear user's cart
   * @param loyalty_id Loyalty ID
   * @return Customer cart object
   */
  incustControllersClientClientUserCartDelete(
    loyaltyId: string
  ): __Observable<CustomerCart> {
    return this.incustControllersClientClientUserCartDeleteResponse(
      loyaltyId
    ).pipe(__map((_r) => _r.body as CustomerCart));
  }

  /**
   * Get user's cart
   * @param loyalty_id Loyalty ID
   * @return Customer cart object
   */
  incustControllersClientClientUserCartGetResponse(
    loyaltyId: string
  ): __Observable<__StrictHttpResponse<CustomerCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/loyalty/${encodeURIComponent(String(loyaltyId))}/cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerCart>;
      })
    );
  }
  /**
   * Get user's cart
   * @param loyalty_id Loyalty ID
   * @return Customer cart object
   */
  incustControllersClientClientUserCartGet(
    loyaltyId: string
  ): __Observable<CustomerCart> {
    return this.incustControllersClientClientUserCartGetResponse(
      loyaltyId
    ).pipe(__map((_r) => _r.body as CustomerCart));
  }

  /**
   * Modify user's cart
   * @param params The `OnlineStoreService.IncustControllersClientClientUserCartModifyParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Customer cart object
   *
   * @return Customer cart object
   */
  incustControllersClientClientUserCartModifyResponse(
    params: OnlineStoreService.IncustControllersClientClientUserCartModifyParams
  ): __Observable<__StrictHttpResponse<CustomerCart>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(String(params.loyaltyId))}/cart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerCart>;
      })
    );
  }
  /**
   * Modify user's cart
   * @param params The `OnlineStoreService.IncustControllersClientClientUserCartModifyParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Customer cart object
   *
   * @return Customer cart object
   */
  incustControllersClientClientUserCartModify(
    params: OnlineStoreService.IncustControllersClientClientUserCartModifyParams
  ): __Observable<CustomerCart> {
    return this.incustControllersClientClientUserCartModifyResponse(
      params
    ).pipe(__map((_r) => _r.body as CustomerCart));
  }

  /**
   * Return loyalty deliveries list
   * @param loyalty_id Loyalty ID
   * @return Delivery types array
   */
  incustControllersClientClientLoyaltyDeliveriesGetResponse(
    loyaltyId: string
  ): __Observable<__StrictHttpResponse<Array<DeliveryType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/loyalty/${encodeURIComponent(String(loyaltyId))}/deliveries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DeliveryType>>;
      })
    );
  }
  /**
   * Return loyalty deliveries list
   * @param loyalty_id Loyalty ID
   * @return Delivery types array
   */
  incustControllersClientClientLoyaltyDeliveriesGet(
    loyaltyId: string
  ): __Observable<Array<DeliveryType>> {
    return this.incustControllersClientClientLoyaltyDeliveriesGetResponse(
      loyaltyId
    ).pipe(__map((_r) => _r.body as Array<DeliveryType>));
  }

  /**
   * Get customers orders list
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStoreLoyaltyOrdersGetParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `page`: List page number
   *
   * - `order_type`: Order type filtering if needed
   *
   * - `count`: Messages count in page
   *
   * @return Orders list with summary
   */
  incustControllersClientClientOnlineStoreLoyaltyOrdersGetResponse(
    params: OnlineStoreService.IncustControllersClientClientOnlineStoreLoyaltyOrdersGetParams
  ): __Observable<__StrictHttpResponse<OrdersListResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.page != null)
      __params = __params.set('page', params.page.toString());
    if (params.orderType != null)
      __params = __params.set('order_type', params.orderType.toString());
    if (params.count != null)
      __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/online_store/loyalty/${encodeURIComponent(
          String(params.loyaltyId)
        )}/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrdersListResult>;
      })
    );
  }
  /**
   * Get customers orders list
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStoreLoyaltyOrdersGetParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `page`: List page number
   *
   * - `order_type`: Order type filtering if needed
   *
   * - `count`: Messages count in page
   *
   * @return Orders list with summary
   */
  incustControllersClientClientOnlineStoreLoyaltyOrdersGet(
    params: OnlineStoreService.IncustControllersClientClientOnlineStoreLoyaltyOrdersGetParams
  ): __Observable<OrdersListResult> {
    return this.incustControllersClientClientOnlineStoreLoyaltyOrdersGetResponse(
      params
    ).pipe(__map((_r) => _r.body as OrdersListResult));
  }

  /**
   * Add customer order
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStoreLoyaltyOrderAddParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Customer order object
   *
   * @return Customer order object
   */
  incustControllersClientClientOnlineStoreLoyaltyOrderAddResponse(
    params: OnlineStoreService.IncustControllersClientClientOnlineStoreLoyaltyOrderAddParams
  ): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/online_store/loyalty/${encodeURIComponent(
          String(params.loyaltyId)
        )}/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * Add customer order
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStoreLoyaltyOrderAddParams` containing the following parameters:
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `data`: Customer order object
   *
   * @return Customer order object
   */
  incustControllersClientClientOnlineStoreLoyaltyOrderAdd(
    params: OnlineStoreService.IncustControllersClientClientOnlineStoreLoyaltyOrderAddParams
  ): __Observable<Order> {
    return this.incustControllersClientClientOnlineStoreLoyaltyOrderAddResponse(
      params
    ).pipe(__map((_r) => _r.body as Order));
  }

  /**
   * Get customers orders list
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStoreOrdersGetParams` containing the following parameters:
   *
   * - `page`: List page number
   *
   * - `order_type`: Order type filtering if needed
   *
   * - `count`: Messages count in page
   *
   * @return Orders list with summary
   */
  incustControllersClientClientOnlineStoreOrdersGetResponse(
    params: OnlineStoreService.IncustControllersClientClientOnlineStoreOrdersGetParams
  ): __Observable<__StrictHttpResponse<OrdersListResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null)
      __params = __params.set('page', params.page.toString());
    if (params.orderType != null)
      __params = __params.set('order_type', params.orderType.toString());
    if (params.count != null)
      __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/online_store/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrdersListResult>;
      })
    );
  }
  /**
   * Get customers orders list
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStoreOrdersGetParams` containing the following parameters:
   *
   * - `page`: List page number
   *
   * - `order_type`: Order type filtering if needed
   *
   * - `count`: Messages count in page
   *
   * @return Orders list with summary
   */
  incustControllersClientClientOnlineStoreOrdersGet(
    params: OnlineStoreService.IncustControllersClientClientOnlineStoreOrdersGetParams
  ): __Observable<OrdersListResult> {
    return this.incustControllersClientClientOnlineStoreOrdersGetResponse(
      params
    ).pipe(__map((_r) => _r.body as OrdersListResult));
  }

  /**
   * Get customers order
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStoreOrderGetParams` containing the following parameters:
   *
   * - `order_id`: Order ID
   *
   * - `processing_only`: Get only processing info
   *
   * @return Customer order object
   */
  incustControllersClientClientOnlineStoreOrderGetResponse(
    params: OnlineStoreService.IncustControllersClientClientOnlineStoreOrderGetParams
  ): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.processingOnly != null)
      __params = __params.set(
        'processing_only',
        params.processingOnly.toString()
      );
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/online_store/orders/${encodeURIComponent(String(params.orderId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * Get customers order
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStoreOrderGetParams` containing the following parameters:
   *
   * - `order_id`: Order ID
   *
   * - `processing_only`: Get only processing info
   *
   * @return Customer order object
   */
  incustControllersClientClientOnlineStoreOrderGet(
    params: OnlineStoreService.IncustControllersClientClientOnlineStoreOrderGetParams
  ): __Observable<Order> {
    return this.incustControllersClientClientOnlineStoreOrderGetResponse(
      params
    ).pipe(__map((_r) => _r.body as Order));
  }

  /**
   * Cancel customers order
   * @param order_id Order ID
   * @return Customer order object
   */
  incustControllersClientClientOnlineStoreOrderCancelResponse(
    orderId: string
  ): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/online_store/orders/${encodeURIComponent(String(orderId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * Cancel customers order
   * @param order_id Order ID
   * @return Customer order object
   */
  incustControllersClientClientOnlineStoreOrderCancel(
    orderId: string
  ): __Observable<Order> {
    return this.incustControllersClientClientOnlineStoreOrderCancelResponse(
      orderId
    ).pipe(__map((_r) => _r.body as Order));
  }

  /**
   * Get customers orders list
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStorePosOrdersGetParams` containing the following parameters:
   *
   * - `pos_id`: Pos ID
   *
   * - `page`: List page number
   *
   * - `order_type`: Order type filtering if needed
   *
   * - `count`: Messages count in page
   *
   * @return Orders list with summary
   */
  incustControllersClientClientOnlineStorePosOrdersGetResponse(
    params: OnlineStoreService.IncustControllersClientClientOnlineStorePosOrdersGetParams
  ): __Observable<__StrictHttpResponse<OrdersListResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.page != null)
      __params = __params.set('page', params.page.toString());
    if (params.orderType != null)
      __params = __params.set('order_type', params.orderType.toString());
    if (params.count != null)
      __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/online_store/pos/${encodeURIComponent(String(params.posId))}/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrdersListResult>;
      })
    );
  }
  /**
   * Get customers orders list
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStorePosOrdersGetParams` containing the following parameters:
   *
   * - `pos_id`: Pos ID
   *
   * - `page`: List page number
   *
   * - `order_type`: Order type filtering if needed
   *
   * - `count`: Messages count in page
   *
   * @return Orders list with summary
   */
  incustControllersClientClientOnlineStorePosOrdersGet(
    params: OnlineStoreService.IncustControllersClientClientOnlineStorePosOrdersGetParams
  ): __Observable<OrdersListResult> {
    return this.incustControllersClientClientOnlineStorePosOrdersGetResponse(
      params
    ).pipe(__map((_r) => _r.body as OrdersListResult));
  }

  /**
   * Add customer order
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStorePosOrderAddParams` containing the following parameters:
   *
   * - `pos_id`: Pos ID
   *
   * - `data`: Customer order object
   *
   * @return Customer order object
   */
  incustControllersClientClientOnlineStorePosOrderAddResponse(
    params: OnlineStoreService.IncustControllersClientClientOnlineStorePosOrderAddParams
  ): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/online_store/pos/${encodeURIComponent(String(params.posId))}/orders`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * Add customer order
   * @param params The `OnlineStoreService.IncustControllersClientClientOnlineStorePosOrderAddParams` containing the following parameters:
   *
   * - `pos_id`: Pos ID
   *
   * - `data`: Customer order object
   *
   * @return Customer order object
   */
  incustControllersClientClientOnlineStorePosOrderAdd(
    params: OnlineStoreService.IncustControllersClientClientOnlineStorePosOrderAddParams
  ): __Observable<Order> {
    return this.incustControllersClientClientOnlineStorePosOrderAddResponse(
      params
    ).pipe(__map((_r) => _r.body as Order));
  }
}

module OnlineStoreService {
  /**
   * Parameters for incustControllersClientClientGetGoodsCategoriesItems
   */
  export interface IncustControllersClientClientGetGoodsCategoriesItemsParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Parent category ID or 'root' for root level categories. If parameter is not set return ALL categories.
     */
    parentId?: string;
  }

  /**
   * Parameters for incustControllersClientClientGetGoodsItems
   */
  export interface IncustControllersClientClientGetGoodsItemsParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Include account customers limits data (only if price_customers_account_info is true)
     */
    priceCustomersAccountInfoExtended?: boolean;

    /**
     * Include account customers data in prices
     */
    priceCustomersAccountInfo?: boolean;

    /**
     * Parent category ID or 'root' for root level items. If parameter is not set return ALL goods items.
     */
    parentId?: string;
  }

  /**
   * Parameters for incustControllersClientClientUserCartModify
   */
  export interface IncustControllersClientClientUserCartModifyParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Customer cart object
     */
    data: CustomerCart;
  }

  /**
   * Parameters for incustControllersClientClientOnlineStoreLoyaltyOrdersGet
   */
  export interface IncustControllersClientClientOnlineStoreLoyaltyOrdersGetParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * List page number
     */
    page?: number;

    /**
     * Order type filtering if needed
     */
    orderType?: null | 'all' | 'online-store' | 'fuel-taxi';

    /**
     * Messages count in page
     */
    count?: number;
  }

  /**
   * Parameters for incustControllersClientClientOnlineStoreLoyaltyOrderAdd
   */
  export interface IncustControllersClientClientOnlineStoreLoyaltyOrderAddParams {
    /**
     * Loyalty ID
     */
    loyaltyId: string;

    /**
     * Customer order object
     */
    data: Order;
  }

  /**
   * Parameters for incustControllersClientClientOnlineStoreOrdersGet
   */
  export interface IncustControllersClientClientOnlineStoreOrdersGetParams {
    /**
     * List page number
     */
    page?: number;

    /**
     * Order type filtering if needed
     */
    orderType?: null | 'all' | 'online-store' | 'fuel-taxi';

    /**
     * Messages count in page
     */
    count?: number;
  }

  /**
   * Parameters for incustControllersClientClientOnlineStoreOrderGet
   */
  export interface IncustControllersClientClientOnlineStoreOrderGetParams {
    /**
     * Order ID
     */
    orderId: string;

    /**
     * Get only processing info
     */
    processingOnly?: boolean;
  }

  /**
   * Parameters for incustControllersClientClientOnlineStorePosOrdersGet
   */
  export interface IncustControllersClientClientOnlineStorePosOrdersGetParams {
    /**
     * Pos ID
     */
    posId: string;

    /**
     * List page number
     */
    page?: number;

    /**
     * Order type filtering if needed
     */
    orderType?: null | 'all' | 'online-store' | 'fuel-taxi';

    /**
     * Messages count in page
     */
    count?: number;
  }

  /**
   * Parameters for incustControllersClientClientOnlineStorePosOrderAdd
   */
  export interface IncustControllersClientClientOnlineStorePosOrderAddParams {
    /**
     * Pos ID
     */
    posId: string;

    /**
     * Customer order object
     */
    data: Order;
  }
}

export { OnlineStoreService };
