@if (coupon$ | async; as coupon) {
    <div class="coupon">
        <div class="container">
            <div class="coupon__holder">
                <a href="{{environment.CLIENT_URL}}/coupon/show/{{ coupon.code }}">
                    <img src="{{ coupon.image }}" alt="{{ coupon.title }}" title="{{ coupon.title }}" class="coupon-picture" />
                    <h1>
                        {{ coupon.title }}
                    </h1>
                    <p [innerHTML]="coupon.description"></p>
                </a>
            </div>
        </div>
    </div>
}
