/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MessageResponse } from '../models/message-response';
import { Coupon } from '../models/coupon';
@Injectable({
  providedIn: 'root'
})
class CouponService extends __BaseService {
  static readonly incustControllersClientClientCouponDeletePath =
    '/coupon/{coupon_id}';
  static readonly incustControllersClientClientCouponGetPath =
    '/coupon/{coupon_id}';
  static readonly incustControllersClientClientCouponClientRedeemPath =
    '/coupon/{coupon_id}/redeem';
  static readonly incustControllersClientClientCouponClientSharePath =
    '/coupon/{coupon_id}/share';
  static readonly incustControllersClientClientCouponsListPath = '/coupons';
  static readonly incustControllersClientClientCouponClientAddPath = '/coupons';
  static readonly incustControllersClientClientCouponInfoPath =
    '/coupons/coupon/{code}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Delete coupon
   * @param coupon_id Coupon ID
   * @return Ok
   */
  incustControllersClientClientCouponDeleteResponse(
    couponId: string
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/coupon/${encodeURIComponent(String(couponId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Delete coupon
   * @param coupon_id Coupon ID
   * @return Ok
   */
  incustControllersClientClientCouponDelete(
    couponId: string
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientCouponDeleteResponse(
      couponId
    ).pipe(__map((_r) => _r.body as MessageResponse));
  }

  /**
   * Returns coupon
   * @param coupon_id Coupon ID
   * @return List of coupons
   */
  incustControllersClientClientCouponGetResponse(
    couponId: string
  ): __Observable<__StrictHttpResponse<Coupon>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coupon/${encodeURIComponent(String(couponId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Coupon>;
      })
    );
  }
  /**
   * Returns coupon
   * @param coupon_id Coupon ID
   * @return List of coupons
   */
  incustControllersClientClientCouponGet(
    couponId: string
  ): __Observable<Coupon> {
    return this.incustControllersClientClientCouponGetResponse(couponId).pipe(
      __map((_r) => _r.body as Coupon)
    );
  }

  /**
   * Redeem coupon
   * @param coupon_id Coupon ID
   * @return Ok
   */
  incustControllersClientClientCouponClientRedeemResponse(
    couponId: string
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coupon/${encodeURIComponent(String(couponId))}/redeem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Redeem coupon
   * @param coupon_id Coupon ID
   * @return Ok
   */
  incustControllersClientClientCouponClientRedeem(
    couponId: string
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientCouponClientRedeemResponse(
      couponId
    ).pipe(__map((_r) => _r.body as MessageResponse));
  }

  /**
   * Returns coupon share CODE
   * @param coupon_id Coupon ID
   * @return Ok
   */
  incustControllersClientClientCouponClientShareResponse(
    couponId: string
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coupon/${encodeURIComponent(String(couponId))}/share`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Returns coupon share CODE
   * @param coupon_id Coupon ID
   * @return Ok
   */
  incustControllersClientClientCouponClientShare(
    couponId: string
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientCouponClientShareResponse(
      couponId
    ).pipe(__map((_r) => _r.body as MessageResponse));
  }

  /**
   * Returns list of coupons
   * @param params The `CouponService.IncustControllersClientClientCouponsListParams` containing the following parameters:
   *
   * - `loyalty`: Loyalty ID
   *
   * - `ids`: Coupon IDs
   *
   * @return List of coupons
   */
  incustControllersClientClientCouponsListResponse(
    params: CouponService.IncustControllersClientClientCouponsListParams
  ): __Observable<__StrictHttpResponse<Array<Coupon>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.loyalty != null)
      __params = __params.set('loyalty', params.loyalty.toString());
    (params.ids || []).forEach((val) => {
      if (val != null) __params = __params.append('ids', val.toString());
    });
    let req = new HttpRequest<any>('GET', this.rootUrl + `/coupons`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Coupon>>;
      })
    );
  }
  /**
   * Returns list of coupons
   * @param params The `CouponService.IncustControllersClientClientCouponsListParams` containing the following parameters:
   *
   * - `loyalty`: Loyalty ID
   *
   * - `ids`: Coupon IDs
   *
   * @return List of coupons
   */
  incustControllersClientClientCouponsList(
    params: CouponService.IncustControllersClientClientCouponsListParams
  ): __Observable<Array<Coupon>> {
    return this.incustControllersClientClientCouponsListResponse(params).pipe(
      __map((_r) => _r.body as Array<Coupon>)
    );
  }

  /**
   * Take Code of coupon, coupons batch or gift card and add coupons to the wallet
   * @param data Coupon add data
   * @return Ok
   */
  incustControllersClientClientCouponClientAddResponse(data: {
    code: string;
    type?: any;
  }): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/coupons`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Take Code of coupon, coupons batch or gift card and add coupons to the wallet
   * @param data Coupon add data
   * @return Ok
   */
  incustControllersClientClientCouponClientAdd(data: {
    code: string;
    type?: any;
  }): __Observable<MessageResponse> {
    return this.incustControllersClientClientCouponClientAddResponse(data).pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }

  /**
   * Coupon info
   * @param code Coupon CODE
   * @return Coupon data
   */
  incustControllersClientClientCouponInfoResponse(
    code: string
  ): __Observable<__StrictHttpResponse<Coupon>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/coupons/coupon/${encodeURIComponent(String(code))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Coupon>;
      })
    );
  }
  /**
   * Coupon info
   * @param code Coupon CODE
   * @return Coupon data
   */
  incustControllersClientClientCouponInfo(code: string): __Observable<Coupon> {
    return this.incustControllersClientClientCouponInfoResponse(code).pipe(
      __map((_r) => _r.body as Coupon)
    );
  }
}

module CouponService {
  /**
   * Parameters for incustControllersClientClientCouponsList
   */
  export interface IncustControllersClientClientCouponsListParams {
    /**
     * Loyalty ID
     */
    loyalty?: string;

    /**
     * Coupon IDs
     */
    ids?: Array<string>;
  }
}

export { CouponService };
