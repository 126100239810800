@if (giftCard$ | async; as card) {
    <div class="gift">
        <div class="container">
            <div class="gift__holder">
                <a href="{{environment.CLIENT_URL}}/card/show/{{ card.code }}">
                    <img src="{{ card.image || card.loyalty?.photos?.[0]?.url || '' }}"
                         alt="{{ card.title }}"
                         title="{{ card.title }}"
                         class="coupon-picture"/>
                    <h1>
                        {{ card.title }}
                    </h1>
                    <p [innerHTML]="card.description">
                    </p>
                </a>
            </div>
        </div>
    </div>
}
