/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MessageResponse } from '../models/message-response';
import { ApplicationSettings } from '../models/application-settings';
@Injectable({
  providedIn: 'root'
})
class SystemService extends __BaseService {
  static readonly incustControllersClientClientAfterLoginPath = '/after_login';
  static readonly incustControllersClientClientApplicationSettingsPath =
    '/settings';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * After user loged in system notification method
   * @param data Application data
   * @return Ok
   */
  incustControllersClientClientAfterLoginResponse(data: {}): __Observable<
    __StrictHttpResponse<MessageResponse>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/after_login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * After user loged in system notification method
   * @param data Application data
   * @return Ok
   */
  incustControllersClientClientAfterLogin(data: {}): __Observable<MessageResponse> {
    return this.incustControllersClientClientAfterLoginResponse(data).pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }

  /**
   * Application settings
   * @return Loyalty settings object
   */
  incustControllersClientClientApplicationSettingsResponse(): __Observable<
    __StrictHttpResponse<ApplicationSettings>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/settings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApplicationSettings>;
      })
    );
  }
  /**
   * Application settings
   * @return Loyalty settings object
   */
  incustControllersClientClientApplicationSettings(): __Observable<ApplicationSettings> {
    return this.incustControllersClientClientApplicationSettingsResponse().pipe(
      __map((_r) => _r.body as ApplicationSettings)
    );
  }
}

module SystemService {}

export { SystemService };
