/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { FeedbackSource } from '../models/feedback-source';
import { CustomerFeedback } from '../models/customer-feedback';
import { MessageResponse } from '../models/message-response';
@Injectable({
  providedIn: 'root'
})
class CustomerFeedbackService extends __BaseService {
  static readonly incustControllersClientClientCustomerFeedbackSourcePath =
    '/customer_feedback/feedback_sources/{source_id}';
  static readonly incustControllersClientClientCustomerFeedbacksPath =
    '/customer_feedback/feedbacks';
  static readonly incustControllersClientClientCustomerFeedbackAddPath =
    '/customer_feedback/feedbacks';
  static readonly incustControllersClientClientCustomerFeedbackDelPath =
    '/customer_feedback/feedbacks/{feedback_id}';
  static readonly incustControllersClientClientCustomerFeedbackGetPath =
    '/customer_feedback/feedbacks/{feedback_id}';
  static readonly incustControllersClientClientCustomerFeedbackEditPath =
    '/customer_feedback/feedbacks/{feedback_id}';
  static readonly incustControllersClientClientCustomerFeedbackAttachmentsPictureAddPath =
    '/customer_feedback/feedbacks/{feedback_id}/attachments/picture';
  static readonly incustControllersClientClientCustomerFeedbackAttachmentsVideoAddPath =
    '/customer_feedback/feedbacks/{feedback_id}/attachments/video';
  static readonly incustControllersClientClientCustomerFeedbackAttachmentsVoiceAddPath =
    '/customer_feedback/feedbacks/{feedback_id}/attachments/voice';
  static readonly incustControllersClientClientCustomerFeedbackAttachmentDeletePath =
    '/customer_feedback/feedbacks/{feedback_id}/attachments/{attachment_id}';
  static readonly incustControllersClientClientCustomerFeedbackAttachmentGetPath =
    '/customer_feedback/feedbacks/{feedback_id}/attachments/{attachment_id}';
  static readonly incustControllersClientClientCustomerFeedbackCompletePath =
    '/customer_feedback/feedbacks/{feedback_id}/complete';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return feedback source information
   * @param source_id Loyalty ID
   * @return Feedback source data
   */
  incustControllersClientClientCustomerFeedbackSourceResponse(
    sourceId: string
  ): __Observable<__StrictHttpResponse<FeedbackSource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/customer_feedback/feedback_sources/${encodeURIComponent(
          String(sourceId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeedbackSource>;
      })
    );
  }
  /**
   * Return feedback source information
   * @param source_id Loyalty ID
   * @return Feedback source data
   */
  incustControllersClientClientCustomerFeedbackSource(
    sourceId: string
  ): __Observable<FeedbackSource> {
    return this.incustControllersClientClientCustomerFeedbackSourceResponse(
      sourceId
    ).pipe(__map((_r) => _r.body as FeedbackSource));
  }

  /**
   * Return customer feedbacks
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbacksParams` containing the following parameters:
   *
   * - `page`: List page number
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `count`: Objects on page
   *
   * @return Feedbacks data array
   */
  incustControllersClientClientCustomerFeedbacksResponse(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbacksParams
  ): __Observable<__StrictHttpResponse<Array<CustomerFeedback>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.page != null)
      __params = __params.set('page', params.page.toString());
    if (params.loyaltyId != null)
      __params = __params.set('loyalty_id', params.loyaltyId.toString());
    if (params.count != null)
      __params = __params.set('count', params.count.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer_feedback/feedbacks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CustomerFeedback>>;
      })
    );
  }
  /**
   * Return customer feedbacks
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbacksParams` containing the following parameters:
   *
   * - `page`: List page number
   *
   * - `loyalty_id`: Loyalty ID
   *
   * - `count`: Objects on page
   *
   * @return Feedbacks data array
   */
  incustControllersClientClientCustomerFeedbacks(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbacksParams
  ): __Observable<Array<CustomerFeedback>> {
    return this.incustControllersClientClientCustomerFeedbacksResponse(
      params
    ).pipe(__map((_r) => _r.body as Array<CustomerFeedback>));
  }

  /**
   * Post/add customer feedback
   * @param data Feedback data object
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAddResponse(
    data: CustomerFeedback
  ): __Observable<__StrictHttpResponse<CustomerFeedback>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/customer_feedback/feedbacks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerFeedback>;
      })
    );
  }
  /**
   * Post/add customer feedback
   * @param data Feedback data object
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAdd(
    data: CustomerFeedback
  ): __Observable<CustomerFeedback> {
    return this.incustControllersClientClientCustomerFeedbackAddResponse(
      data
    ).pipe(__map((_r) => _r.body as CustomerFeedback));
  }

  /**
   * Remove customer feedback
   * @param feedback_id Feedback ID
   * @return Ok
   */
  incustControllersClientClientCustomerFeedbackDelResponse(
    feedbackId: string
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/customer_feedback/feedbacks/${encodeURIComponent(
          String(feedbackId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Remove customer feedback
   * @param feedback_id Feedback ID
   * @return Ok
   */
  incustControllersClientClientCustomerFeedbackDel(
    feedbackId: string
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientCustomerFeedbackDelResponse(
      feedbackId
    ).pipe(__map((_r) => _r.body as MessageResponse));
  }

  /**
   * Return customer feedback data
   * @param feedback_id Feedback ID
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackGetResponse(
    feedbackId: string
  ): __Observable<__StrictHttpResponse<CustomerFeedback>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/customer_feedback/feedbacks/${encodeURIComponent(
          String(feedbackId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerFeedback>;
      })
    );
  }
  /**
   * Return customer feedback data
   * @param feedback_id Feedback ID
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackGet(
    feedbackId: string
  ): __Observable<CustomerFeedback> {
    return this.incustControllersClientClientCustomerFeedbackGetResponse(
      feedbackId
    ).pipe(__map((_r) => _r.body as CustomerFeedback));
  }

  /**
   * Modify customer feedback
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackEditParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Feedback data object
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackEditResponse(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackEditParams
  ): __Observable<__StrictHttpResponse<CustomerFeedback>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl +
        `/customer_feedback/feedbacks/${encodeURIComponent(
          String(params.feedbackId)
        )}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerFeedback>;
      })
    );
  }
  /**
   * Modify customer feedback
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackEditParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Feedback data object
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackEdit(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackEditParams
  ): __Observable<CustomerFeedback> {
    return this.incustControllersClientClientCustomerFeedbackEditResponse(
      params
    ).pipe(__map((_r) => _r.body as CustomerFeedback));
  }

  /**
   * Customer feedback add attachment image file
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsPictureAddParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Octet stream
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAttachmentsPictureAddResponse(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsPictureAddParams
  ): __Observable<__StrictHttpResponse<CustomerFeedback>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/customer_feedback/feedbacks/${encodeURIComponent(
          String(params.feedbackId)
        )}/attachments/picture`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerFeedback>;
      })
    );
  }
  /**
   * Customer feedback add attachment image file
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsPictureAddParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Octet stream
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAttachmentsPictureAdd(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsPictureAddParams
  ): __Observable<CustomerFeedback> {
    return this.incustControllersClientClientCustomerFeedbackAttachmentsPictureAddResponse(
      params
    ).pipe(__map((_r) => _r.body as CustomerFeedback));
  }

  /**
   * Customer feedback add attachment video file
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsVideoAddParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Octet stream
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAttachmentsVideoAddResponse(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsVideoAddParams
  ): __Observable<__StrictHttpResponse<CustomerFeedback>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/customer_feedback/feedbacks/${encodeURIComponent(
          String(params.feedbackId)
        )}/attachments/video`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerFeedback>;
      })
    );
  }
  /**
   * Customer feedback add attachment video file
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsVideoAddParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Octet stream
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAttachmentsVideoAdd(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsVideoAddParams
  ): __Observable<CustomerFeedback> {
    return this.incustControllersClientClientCustomerFeedbackAttachmentsVideoAddResponse(
      params
    ).pipe(__map((_r) => _r.body as CustomerFeedback));
  }

  /**
   * Customer feedback add attachment audio file
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsVoiceAddParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Octet stream
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAttachmentsVoiceAddResponse(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsVoiceAddParams
  ): __Observable<__StrictHttpResponse<CustomerFeedback>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/customer_feedback/feedbacks/${encodeURIComponent(
          String(params.feedbackId)
        )}/attachments/voice`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerFeedback>;
      })
    );
  }
  /**
   * Customer feedback add attachment audio file
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsVoiceAddParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Octet stream
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAttachmentsVoiceAdd(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentsVoiceAddParams
  ): __Observable<CustomerFeedback> {
    return this.incustControllersClientClientCustomerFeedbackAttachmentsVoiceAddResponse(
      params
    ).pipe(__map((_r) => _r.body as CustomerFeedback));
  }

  /**
   * Customer feedback attachment file delete
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentDeleteParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `attachment_id`: Attachment ID
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAttachmentDeleteResponse(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentDeleteParams
  ): __Observable<__StrictHttpResponse<CustomerFeedback>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/customer_feedback/feedbacks/${encodeURIComponent(
          String(params.feedbackId)
        )}/attachments/${encodeURIComponent(String(params.attachmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerFeedback>;
      })
    );
  }
  /**
   * Customer feedback attachment file delete
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentDeleteParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `attachment_id`: Attachment ID
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackAttachmentDelete(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentDeleteParams
  ): __Observable<CustomerFeedback> {
    return this.incustControllersClientClientCustomerFeedbackAttachmentDeleteResponse(
      params
    ).pipe(__map((_r) => _r.body as CustomerFeedback));
  }

  /**
   * Customer feedback attachment file get
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentGetParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `attachment_id`: Attachment ID
   */
  incustControllersClientClientCustomerFeedbackAttachmentGetResponse(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentGetParams
  ): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/customer_feedback/feedbacks/${encodeURIComponent(
          String(params.feedbackId)
        )}/attachments/${encodeURIComponent(String(params.attachmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Customer feedback attachment file get
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentGetParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `attachment_id`: Attachment ID
   */
  incustControllersClientClientCustomerFeedbackAttachmentGet(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackAttachmentGetParams
  ): __Observable<null> {
    return this.incustControllersClientClientCustomerFeedbackAttachmentGetResponse(
      params
    ).pipe(__map((_r) => _r.body as null));
  }

  /**
   * Complete customer feedback
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackCompleteParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Command details
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackCompleteResponse(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackCompleteParams
  ): __Observable<__StrictHttpResponse<CustomerFeedback>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/customer_feedback/feedbacks/${encodeURIComponent(
          String(params.feedbackId)
        )}/complete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerFeedback>;
      })
    );
  }
  /**
   * Complete customer feedback
   * @param params The `CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackCompleteParams` containing the following parameters:
   *
   * - `feedback_id`: Feedback ID
   *
   * - `data`: Command details
   *
   * @return Feedback data object
   */
  incustControllersClientClientCustomerFeedbackComplete(
    params: CustomerFeedbackService.IncustControllersClientClientCustomerFeedbackCompleteParams
  ): __Observable<CustomerFeedback> {
    return this.incustControllersClientClientCustomerFeedbackCompleteResponse(
      params
    ).pipe(__map((_r) => _r.body as CustomerFeedback));
  }
}

module CustomerFeedbackService {
  /**
   * Parameters for incustControllersClientClientCustomerFeedbacks
   */
  export interface IncustControllersClientClientCustomerFeedbacksParams {
    /**
     * List page number
     */
    page?: number;

    /**
     * Loyalty ID
     */
    loyaltyId?: string;

    /**
     * Objects on page
     */
    count?: number;
  }

  /**
   * Parameters for incustControllersClientClientCustomerFeedbackEdit
   */
  export interface IncustControllersClientClientCustomerFeedbackEditParams {
    /**
     * Feedback ID
     */
    feedbackId: string;

    /**
     * Feedback data object
     */
    data: CustomerFeedback;
  }

  /**
   * Parameters for incustControllersClientClientCustomerFeedbackAttachmentsPictureAdd
   */
  export interface IncustControllersClientClientCustomerFeedbackAttachmentsPictureAddParams {
    /**
     * Feedback ID
     */
    feedbackId: string;

    /**
     * Octet stream
     */
    data: any;
  }

  /**
   * Parameters for incustControllersClientClientCustomerFeedbackAttachmentsVideoAdd
   */
  export interface IncustControllersClientClientCustomerFeedbackAttachmentsVideoAddParams {
    /**
     * Feedback ID
     */
    feedbackId: string;

    /**
     * Octet stream
     */
    data: any;
  }

  /**
   * Parameters for incustControllersClientClientCustomerFeedbackAttachmentsVoiceAdd
   */
  export interface IncustControllersClientClientCustomerFeedbackAttachmentsVoiceAddParams {
    /**
     * Feedback ID
     */
    feedbackId: string;

    /**
     * Octet stream
     */
    data: any;
  }

  /**
   * Parameters for incustControllersClientClientCustomerFeedbackAttachmentDelete
   */
  export interface IncustControllersClientClientCustomerFeedbackAttachmentDeleteParams {
    /**
     * Feedback ID
     */
    feedbackId: string;

    /**
     * Attachment ID
     */
    attachmentId: string;
  }

  /**
   * Parameters for incustControllersClientClientCustomerFeedbackAttachmentGet
   */
  export interface IncustControllersClientClientCustomerFeedbackAttachmentGetParams {
    /**
     * Feedback ID
     */
    feedbackId: string;

    /**
     * Attachment ID
     */
    attachmentId: string;
  }

  /**
   * Parameters for incustControllersClientClientCustomerFeedbackComplete
   */
  export interface IncustControllersClientClientCustomerFeedbackCompleteParams {
    /**
     * Feedback ID
     */
    feedbackId: string;

    /**
     * Command details
     */
    data: { commit?: boolean };
  }
}

export { CustomerFeedbackService };
