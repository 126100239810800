@if (pos$ | async; as pos) {
    <div class="container">
        @if (pos.photos && pos.photos[0]) {
            <div class="logo-holder">
                <img src="{{pos.photos[0].url || pos.photos[0]}}" alt="">
            </div>
        }

        <h3>{{ pos.title }}</h3>

        @if (fuelGoods && fuelGoods.length > 0) {
            <div class="cards-holder">
                @for (good of fuelGoods; track good.id) {
                    @if (good.price) {
                        <div class="card">
                            <strong class="title">{{ good.title }}</strong>
                            <div class="card-info">
                                <img src="{{ good.image?good.image.url: 'dummy_pos.png'}}" alt="">
                                <div class="price">{{ good?.price | currency }}</div>
                            </div>
                        </div>
                    }
                }
            </div>
        } @else {
            <div class="no-data">
                <span> No data available </span>
            </div>
        }
    </div>
} @else {
    <div class="no-data">
        <span> Pos not available </span>
    </div>
}
