import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AsyncPipe } from '@angular/common';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { GiftCardService } from '../../api/services';
import { GiftCard } from '../../api/models/gift-card';

@Component({
  selector: 'app-gift-cards-view',
  standalone: true,
  imports: [AsyncPipe],
  templateUrl: './gift-cards.component.html',
  styleUrl: './gift-cards.component.scss'
})
export class GiftCardsComponent implements OnInit {
  protected readonly environment = environment;
  protected readonly destroyRef = inject(DestroyRef);
  private giftCardService = inject(GiftCardService);
  private metaService = inject(Meta);
  private titleService = inject(Title);
  private route = inject(ActivatedRoute);
  private destroy$ = new Subject<void>();
  public giftCard$?: Observable<GiftCard>;

  ngOnInit() {
    this.fetchData();

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  fetchData() {
    const cardId = this.route.snapshot.paramMap.get('cardId');

    if (!cardId) {
      return;
    }

    this.giftCard$ = this.giftCardService
      .incustControllersClientClientGiftCardInfo(cardId)
      .pipe(
        takeUntil(this.destroy$),
        map((res: GiftCard) => {
          console.log('Gift card: ', res);
          this.setMetaTags(res);
          // window.open(
          //   `${environment.CLIENT_URL}/card/show/${res.code}?lang=${
          //     lang || 'en'
          //   }`,
          //   '_self'
          // );
          return res;
        })
      );
  }

  getUrl(code?: string | null) {
    if (!code) {
      return '';
    }

    const lang = this.route.snapshot.queryParamMap.get('lang') || null;
    const from = this.route.snapshot.queryParamMap.get('from_src') || null;
    return `${
      from ? 'https://' + from : environment.CLIENT_URL
    }/card/show/${code}?lang=${lang || 'en'}`;
  }

  setMetaTags(data: GiftCard) {
    this.titleService.setTitle(data.title || 'Default Title');
    this.metaService.addTags([
      {
        name: 'description',
        content: data.description || 'Default description'
      },
      { name: 'keywords', content: data.title || 'angular,ssr,example' },
      { name: 'twitter:card', content: 'summary' },
      // {name: 'twitter:site', content: `@`},
      { name: 'og:type', content: 'website' },
      { name: 'og:site_name', content: data.title || 'Default Title' },
      { name: 'og:title', content: data.title || 'Default Title' },
      {
        name: 'og:url',
        content: this.getUrl(data.code)
      },
      {
        name: 'og:description',
        content: data.description || 'Default description'
      },
      {
        name: 'og:image',
        content: data.image || data.loyalty?.photos?.[0]?.url || ''
      },
      { name: 'og:image:width', content: '600' },
      { name: 'og:image:height', content: '315' }
    ]);

    this.metaService.updateTag({
      rel: 'canonical',
      href: this.getUrl(data.code)
    });
  }
}
