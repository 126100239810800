/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MessageResponse } from '../models/message-response';
import { FavoriteObjectAction } from '../models/favorite-object-action';
@Injectable({
  providedIn: 'root'
})
class FavoriteService extends __BaseService {
  static readonly incustControllersClientClientFavoriteDelelePath = '/favorite';
  static readonly incustControllersClientClientFavoriteAddPath = '/favorite';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Remove object from favorite
   * @param data Favorite object data
   * @return Ok
   */
  incustControllersClientClientFavoriteDeleleResponse(
    data?: FavoriteObjectAction
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/favorite`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Remove object from favorite
   * @param data Favorite object data
   * @return Ok
   */
  incustControllersClientClientFavoriteDelele(
    data?: FavoriteObjectAction
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientFavoriteDeleleResponse(data).pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }

  /**
   * Add object to favorite
   * @param data Favorite object data
   * @return Ok
   */
  incustControllersClientClientFavoriteAddResponse(
    data?: FavoriteObjectAction
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/favorite`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Add object to favorite
   * @param data Favorite object data
   * @return Ok
   */
  incustControllersClientClientFavoriteAdd(
    data?: FavoriteObjectAction
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientFavoriteAddResponse(data).pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }
}

module FavoriteService {}

export { FavoriteService };
