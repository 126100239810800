import { Route } from '@angular/router';
import { FuelPricesComponent } from './pages/fuel-prices/fuel-prices.component';
import { EquipmentPricesComponent } from './pages/equipment-prices/equipment-prices.component';
import { CouponComponent } from './pages/coupon/coupon.component';
import { GiftCardsComponent } from './pages/gift-cards/gift-cards.component';
import { InvitationComponent } from './pages/invitation/invitation.component';

export const appRoutes: Route[] = [
  {
    path: 'pos/:posId/fuel',
    component: FuelPricesComponent,
    pathMatch: 'full'
  },
  {
    path: 'pos/:posId/equipment',
    component: EquipmentPricesComponent,
    pathMatch: 'full'
  },
  {
    path: 'coupon/:couponId',
    component: CouponComponent,
    pathMatch: 'full'
  },
  {
    path: 'gift_card/:cardId',
    component: GiftCardsComponent,
    pathMatch: 'full'
  },
  {
    path: 'invitation/:code',
    component: InvitationComponent,
    pathMatch: 'full'
  }
];
