/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MessageResponse } from '../models/message-response';
import { Beacon } from '../models/beacon';
@Injectable({
  providedIn: 'root'
})
class BeaconService extends __BaseService {
  static readonly incustControllersClientClientBeaconListPath = '/beacon';
  static readonly incustControllersClientClientBeaconFindPath = '/beacon';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return beacons list
   * @return List a beacons
   */
  incustControllersClientClientBeaconListResponse(): __Observable<
    __StrictHttpResponse<Array<string>>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/beacon`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Return beacons list
   * @return List a beacons
   */
  incustControllersClientClientBeaconList(): __Observable<Array<string>> {
    return this.incustControllersClientClientBeaconListResponse().pipe(
      __map((_r) => _r.body as Array<string>)
    );
  }

  /**
   * Process on find beacons
   * @param data List of beacons
   * @return Ok
   */
  incustControllersClientClientBeaconFindResponse(
    data: Array<Beacon>
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/beacon`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Process on find beacons
   * @param data List of beacons
   * @return Ok
   */
  incustControllersClientClientBeaconFind(
    data: Array<Beacon>
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientBeaconFindResponse(data).pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }
}

module BeaconService {}

export { BeaconService };
