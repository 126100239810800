import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { POSService } from '../../api/services';
import { Pos } from '../../api/models/pos';
import { AsyncPipe, NgClass } from '@angular/common';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-equipment-prices',
  standalone: true,
  imports: [AsyncPipe, NgClass],
  templateUrl: './equipment-prices.component.html',
  styleUrl: './equipment-prices.component.scss'
})
export class EquipmentPricesComponent implements OnInit {
  protected readonly destroyRef = inject(DestroyRef);
  private posService = inject(POSService);
  private metaService = inject(Meta);
  private titleService = inject(Title);
  private route = inject(ActivatedRoute);

  private destroy$ = new Subject<void>();
  public pos$?: Observable<Pos>;

  ngOnInit() {
    this.fetchData();

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  fetchData() {
    const posId = this.route.snapshot.paramMap.get('posId');

    if (!posId) {
      return;
    }

    const query: POSService.IncustControllersClientClientPosDetailParams = {
      posId: posId,
      bonuses: 0,
      coupons: 0,
      cardCategories: 0,
      fuelInfo: 0,
      fuelInfoRecommendedValuesPresets: 0,
      fuelInfoTaxesValues: 0,
      fuelInfoMarginsValues: 0,
      equipmentsInfo: 1,
      fuelInfoPricesValuesScope: 'minimal'
    };

    this.pos$ = this.posService
      .incustControllersClientClientPosDetail(query)
      .pipe(
        takeUntil(this.destroy$),
        map((res) => {
          this.setMetaTags(res);
          return res;
        })
      );
  }

  getUrl(id?: string | null) {
    if (!id) {
      return '';
    }

    const lang = this.route.snapshot.queryParamMap.get('lang') || null;
    const from = this.route.snapshot.queryParamMap.get('from_src') || null;
    return `${
      from ? 'https://' + from : environment.SELF_URL
    }/pos/${id}/fuel?lang=${lang || 'en'}`;
  }

  setMetaTags(data: Pos) {
    const links: any = data.links;
    this.titleService.setTitle(
      data.public_title || data.title || 'Default Title'
    );
    this.metaService.addTags([
      {
        name: 'description',
        content: data.description || 'Default description'
      },
      {
        name: 'keywords',
        content: data.public_title || data.title || 'angular,ssr,example'
      },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: `@${links.web || ''}` },
      { name: 'og:type', content: 'website' },
      {
        name: 'og:site_name',
        content: data.public_title || data.title || 'Default Title'
      },
      {
        name: 'og:title',
        content: data.public_title || data.title || 'Default Title'
      },
      {
        name: 'og:url',
        content: this.getUrl(data.id)
      },
      {
        name: 'og:description',
        content: data.description || 'Default description'
      },
      {
        name: 'og:image',
        content: (data.photos && data.photos[0] && data.photos[0].url) || ''
      },
      { name: 'og:image:width', content: '600' },
      { name: 'og:image:height', content: '315' }
    ]);
  }
}
