/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Pos } from '../models/pos';
import { PosBonuses } from '../models/pos-bonuses';
import { PosEquipments } from '../models/pos-equipments';
import { PosFuel } from '../models/pos-fuel';
import { NewsCategory } from '../models/news-category';
@Injectable({
  providedIn: 'root'
})
class POSService extends __BaseService {
  static readonly incustControllersClientClientPosPath = '/pos';
  static readonly incustControllersClientClientPosDetailPath = '/pos/{pos_id}';
  static readonly incustControllersClientClientPosBonusesPath =
    '/pos/{pos_id}/bonuses';
  static readonly incustControllersClientClientPosEquipmentsPath =
    '/pos/{pos_id}/equipments';
  static readonly incustControllersClientClientPosFuelPath =
    '/pos/{pos_id}/fuel';
  static readonly incustControllersClientClientPosNewsPath =
    '/pos/{pos_id}/news';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return all list of Point of sales (POS)
   * @param params The `POSService.IncustControllersClientClientPosParams` containing the following parameters:
   *
   * - `sort_by_priority`: Sort POS by priority (0/1)
   *
   * - `referral_program_info_scope`: Scope for return referral program info
   *
   * - `referral_program`: Return referral program info (0/1)
   *
   * - `recent`: Filter by recent (0/1)
   *
   * - `radius`: Filter by radius in Km (0 - without filtering)
   *
   * - `q`: Search string
   *
   * - `pos_type`: Pos type filter if need
   *
   * - `payment_systems_availability_scope`: Scope for return payment systems availability info
   *
   * - `payment_systems_availability`: Return payment systems availability info (0/1)
   *
   * - `only_with_location`: Filter only with geolocation (0/1)
   *
   * - `online_store_order_type`: Online store order type (only with pos_type eq 'online-store')
   *
   * - `online_store`: Return online store info (0/1)
   *
   * - `loyalty`: Loyalty ID
   *
   * - `longitude`: Client longitude
   *
   * - `limit`: Result rows limit
   *
   * - `latitude`: Client latitude
   *
   * - `fuel_info_taxes_values`: Return taxes for price in fuel info (0/1)
   *
   * - `fuel_info_recommended_values_presets`: Return recommended values presets in fuel info (0/1)
   *
   * - `fuel_info_prices_values_scope`: Scope for return available prices (not only base) in fuel info
   *
   * - `fuel_info_prices_values`: Return available prices by scope (not only base) in fuel info (0/1)
   *
   * - `fuel_info_margins_values`: Return margins for price in fuel info (0/1)
   *
   * - `fuel_info`: Return fuel info (0/1)
   *
   * - `from_row_offset`: Result start row offset
   *
   * - `favorite`: Filter by favorite (0/1)
   *
   * - `equipments_info`: Return equipments info (0/1)
   *
   * - `category`: Category ID
   *
   * - `additional_services`: Return additional services info (0/1)
   *
   * @return POS array
   */
  incustControllersClientClientPosResponse(
    params: POSService.IncustControllersClientClientPosParams
  ): __Observable<__StrictHttpResponse<Array<Pos>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortByPriority != null)
      __params = __params.set(
        'sort_by_priority',
        params.sortByPriority.toString()
      );
    if (params.referralProgramInfoScope != null)
      __params = __params.set(
        'referral_program_info_scope',
        params.referralProgramInfoScope.toString()
      );
    if (params.referralProgram != null)
      __params = __params.set(
        'referral_program',
        params.referralProgram.toString()
      );
    if (params.recent != null)
      __params = __params.set('recent', params.recent.toString());
    if (params.radius != null)
      __params = __params.set('radius', params.radius.toString());
    if (params.q != null) __params = __params.set('q', params.q.toString());
    if (params.posType != null)
      __params = __params.set('pos_type', params.posType.toString());
    if (params.paymentSystemsAvailabilityScope != null)
      __params = __params.set(
        'payment_systems_availability_scope',
        params.paymentSystemsAvailabilityScope.toString()
      );
    if (params.paymentSystemsAvailability != null)
      __params = __params.set(
        'payment_systems_availability',
        params.paymentSystemsAvailability.toString()
      );
    if (params.onlyWithLocation != null)
      __params = __params.set(
        'only_with_location',
        params.onlyWithLocation.toString()
      );
    if (params.onlineStoreOrderType != null)
      __params = __params.set(
        'online_store_order_type',
        params.onlineStoreOrderType.toString()
      );
    if (params.onlineStore != null)
      __params = __params.set('online_store', params.onlineStore.toString());
    if (params.loyalty != null)
      __params = __params.set('loyalty', params.loyalty.toString());
    if (params.longitude != null)
      __params = __params.set('longitude', params.longitude.toString());
    if (params.limit != null)
      __params = __params.set('limit', params.limit.toString());
    if (params.latitude != null)
      __params = __params.set('latitude', params.latitude.toString());
    if (params.fuelInfoTaxesValues != null)
      __params = __params.set(
        'fuel_info_taxes_values',
        params.fuelInfoTaxesValues.toString()
      );
    if (params.fuelInfoRecommendedValuesPresets != null)
      __params = __params.set(
        'fuel_info_recommended_values_presets',
        params.fuelInfoRecommendedValuesPresets.toString()
      );
    if (params.fuelInfoPricesValuesScope != null)
      __params = __params.set(
        'fuel_info_prices_values_scope',
        params.fuelInfoPricesValuesScope.toString()
      );
    if (params.fuelInfoPricesValues != null)
      __params = __params.set(
        'fuel_info_prices_values',
        params.fuelInfoPricesValues.toString()
      );
    if (params.fuelInfoMarginsValues != null)
      __params = __params.set(
        'fuel_info_margins_values',
        params.fuelInfoMarginsValues.toString()
      );
    if (params.fuelInfo != null)
      __params = __params.set('fuel_info', params.fuelInfo.toString());
    if (params.fromRowOffset != null)
      __params = __params.set(
        'from_row_offset',
        params.fromRowOffset.toString()
      );
    if (params.favorite != null)
      __params = __params.set('favorite', params.favorite.toString());
    if (params.equipmentsInfo != null)
      __params = __params.set(
        'equipments_info',
        params.equipmentsInfo.toString()
      );
    if (params.category != null)
      __params = __params.set('category', params.category.toString());
    if (params.additionalServices != null)
      __params = __params.set(
        'additional_services',
        params.additionalServices.toString()
      );
    let req = new HttpRequest<any>('GET', this.rootUrl + `/pos`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Pos>>;
      })
    );
  }
  /**
   * Return all list of Point of sales (POS)
   * @param params The `POSService.IncustControllersClientClientPosParams` containing the following parameters:
   *
   * - `sort_by_priority`: Sort POS by priority (0/1)
   *
   * - `referral_program_info_scope`: Scope for return referral program info
   *
   * - `referral_program`: Return referral program info (0/1)
   *
   * - `recent`: Filter by recent (0/1)
   *
   * - `radius`: Filter by radius in Km (0 - without filtering)
   *
   * - `q`: Search string
   *
   * - `pos_type`: Pos type filter if need
   *
   * - `payment_systems_availability_scope`: Scope for return payment systems availability info
   *
   * - `payment_systems_availability`: Return payment systems availability info (0/1)
   *
   * - `only_with_location`: Filter only with geolocation (0/1)
   *
   * - `online_store_order_type`: Online store order type (only with pos_type eq 'online-store')
   *
   * - `online_store`: Return online store info (0/1)
   *
   * - `loyalty`: Loyalty ID
   *
   * - `longitude`: Client longitude
   *
   * - `limit`: Result rows limit
   *
   * - `latitude`: Client latitude
   *
   * - `fuel_info_taxes_values`: Return taxes for price in fuel info (0/1)
   *
   * - `fuel_info_recommended_values_presets`: Return recommended values presets in fuel info (0/1)
   *
   * - `fuel_info_prices_values_scope`: Scope for return available prices (not only base) in fuel info
   *
   * - `fuel_info_prices_values`: Return available prices by scope (not only base) in fuel info (0/1)
   *
   * - `fuel_info_margins_values`: Return margins for price in fuel info (0/1)
   *
   * - `fuel_info`: Return fuel info (0/1)
   *
   * - `from_row_offset`: Result start row offset
   *
   * - `favorite`: Filter by favorite (0/1)
   *
   * - `equipments_info`: Return equipments info (0/1)
   *
   * - `category`: Category ID
   *
   * - `additional_services`: Return additional services info (0/1)
   *
   * @return POS array
   */
  incustControllersClientClientPos(
    params: POSService.IncustControllersClientClientPosParams
  ): __Observable<Array<Pos>> {
    return this.incustControllersClientClientPosResponse(params).pipe(
      __map((_r) => _r.body as Array<Pos>)
    );
  }

  /**
   * Return Point of sales (POS)
   * @param params The `POSService.IncustControllersClientClientPosDetailParams` containing the following parameters:
   *
   * - `pos_id`: Point of sales ID
   *
   * - `fuel_info_taxes_values`: Return taxes for price in fuel info (0/1)
   *
   * - `fuel_info_recommended_values_presets`: Return recommended values presets in fuel info (0/1)
   *
   * - `fuel_info_prices_values_scope`: Scope for return available prices (not only base) in fuel info
   *
   * - `fuel_info_prices_values`: Return available prices by scope (not only base) in fuel info (0/1)
   *
   * - `fuel_info_price_customers_account_info_extended`: Include account customers limits data (only if fuel_info_price_customers_account_info is 1)
   *
   * - `fuel_info_price_customers_account_info`: Include account customers data in prices (0/1)
   *
   * - `fuel_info_margins_values`: Return margins for price in fuel info (0/1)
   *
   * - `fuel_info`: Return fuel info (0/1)
   *
   * - `equipments_info`: Return equipments info (0/1)
   *
   * - `coupons`: Return coupons info (0/1)
   *
   * - `card_categories`: Return card categories info (0/1)
   *
   * - `bonuses`: Return bonuses and accounts info (0/1)
   *
   * @return POS object
   */
  incustControllersClientClientPosDetailResponse(
    params: POSService.IncustControllersClientClientPosDetailParams
  ): __Observable<__StrictHttpResponse<Pos>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.fuelInfoTaxesValues != null)
      __params = __params.set(
        'fuel_info_taxes_values',
        params.fuelInfoTaxesValues.toString()
      );
    if (params.fuelInfoRecommendedValuesPresets != null)
      __params = __params.set(
        'fuel_info_recommended_values_presets',
        params.fuelInfoRecommendedValuesPresets.toString()
      );
    if (params.fuelInfoPricesValuesScope != null)
      __params = __params.set(
        'fuel_info_prices_values_scope',
        params.fuelInfoPricesValuesScope.toString()
      );
    if (params.fuelInfoPricesValues != null)
      __params = __params.set(
        'fuel_info_prices_values',
        params.fuelInfoPricesValues.toString()
      );
    if (params.fuelInfoPriceCustomersAccountInfoExtended != null)
      __params = __params.set(
        'fuel_info_price_customers_account_info_extended',
        params.fuelInfoPriceCustomersAccountInfoExtended.toString()
      );
    if (params.fuelInfoPriceCustomersAccountInfo != null)
      __params = __params.set(
        'fuel_info_price_customers_account_info',
        params.fuelInfoPriceCustomersAccountInfo.toString()
      );
    if (params.fuelInfoMarginsValues != null)
      __params = __params.set(
        'fuel_info_margins_values',
        params.fuelInfoMarginsValues.toString()
      );
    if (params.fuelInfo != null)
      __params = __params.set('fuel_info', params.fuelInfo.toString());
    if (params.equipmentsInfo != null)
      __params = __params.set(
        'equipments_info',
        params.equipmentsInfo.toString()
      );
    if (params.coupons != null)
      __params = __params.set('coupons', params.coupons.toString());
    if (params.cardCategories != null)
      __params = __params.set(
        'card_categories',
        params.cardCategories.toString()
      );
    if (params.bonuses != null)
      __params = __params.set('bonuses', params.bonuses.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pos/${encodeURIComponent(String(params.posId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Pos>;
      })
    );
  }
  /**
   * Return Point of sales (POS)
   * @param params The `POSService.IncustControllersClientClientPosDetailParams` containing the following parameters:
   *
   * - `pos_id`: Point of sales ID
   *
   * - `fuel_info_taxes_values`: Return taxes for price in fuel info (0/1)
   *
   * - `fuel_info_recommended_values_presets`: Return recommended values presets in fuel info (0/1)
   *
   * - `fuel_info_prices_values_scope`: Scope for return available prices (not only base) in fuel info
   *
   * - `fuel_info_prices_values`: Return available prices by scope (not only base) in fuel info (0/1)
   *
   * - `fuel_info_price_customers_account_info_extended`: Include account customers limits data (only if fuel_info_price_customers_account_info is 1)
   *
   * - `fuel_info_price_customers_account_info`: Include account customers data in prices (0/1)
   *
   * - `fuel_info_margins_values`: Return margins for price in fuel info (0/1)
   *
   * - `fuel_info`: Return fuel info (0/1)
   *
   * - `equipments_info`: Return equipments info (0/1)
   *
   * - `coupons`: Return coupons info (0/1)
   *
   * - `card_categories`: Return card categories info (0/1)
   *
   * - `bonuses`: Return bonuses and accounts info (0/1)
   *
   * @return POS object
   */
  incustControllersClientClientPosDetail(
    params: POSService.IncustControllersClientClientPosDetailParams
  ): __Observable<Pos> {
    return this.incustControllersClientClientPosDetailResponse(params).pipe(
      __map((_r) => _r.body as Pos)
    );
  }

  /**
   * Return bonuses for Point of sales (POS)
   * @param pos_id Point of sales ID
   * @return POS object
   */
  incustControllersClientClientPosBonusesResponse(
    posId: string
  ): __Observable<__StrictHttpResponse<PosBonuses>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pos/${encodeURIComponent(String(posId))}/bonuses`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosBonuses>;
      })
    );
  }
  /**
   * Return bonuses for Point of sales (POS)
   * @param pos_id Point of sales ID
   * @return POS object
   */
  incustControllersClientClientPosBonuses(
    posId: string
  ): __Observable<PosBonuses> {
    return this.incustControllersClientClientPosBonusesResponse(posId).pipe(
      __map((_r) => _r.body as PosBonuses)
    );
  }

  /**
   * Return equipments of Point of sales (POS)
   * @param pos_id Point of sales ID
   * @return POS object
   */
  incustControllersClientClientPosEquipmentsResponse(
    posId: string
  ): __Observable<__StrictHttpResponse<PosEquipments>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pos/${encodeURIComponent(String(posId))}/equipments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosEquipments>;
      })
    );
  }
  /**
   * Return equipments of Point of sales (POS)
   * @param pos_id Point of sales ID
   * @return POS object
   */
  incustControllersClientClientPosEquipments(
    posId: string
  ): __Observable<PosEquipments> {
    return this.incustControllersClientClientPosEquipmentsResponse(posId).pipe(
      __map((_r) => _r.body as PosEquipments)
    );
  }

  /**
   * Return fuel controller of Point of sales (POS)
   * @param params The `POSService.IncustControllersClientClientPosFuelParams` containing the following parameters:
   *
   * - `pos_id`: Point of sales ID
   *
   * - `taxes_values`: Return taxes for price in fuel info (0/1)
   *
   * - `recommended_values_presets`: Return recommended values presets in fuel info (0/1)
   *
   * - `prices_values_scope`: Scope for return available prices (not only base) in fuel info
   *
   * - `prices_values`: Return available prices by scope (not only base) in fuel info (0/1)
   *
   * - `price_customers_account_info_extended`: Include account customers limits data (only if price_customers_account_info is 1)
   *
   * - `price_customers_account_info`: Include account customers data in prices (0/1)
   *
   * - `margins_values`: Return margins for price in fuel info (0/1)
   *
   * @return POS object
   */
  incustControllersClientClientPosFuelResponse(
    params: POSService.IncustControllersClientClientPosFuelParams
  ): __Observable<__StrictHttpResponse<PosFuel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.taxesValues != null)
      __params = __params.set('taxes_values', params.taxesValues.toString());
    if (params.recommendedValuesPresets != null)
      __params = __params.set(
        'recommended_values_presets',
        params.recommendedValuesPresets.toString()
      );
    if (params.pricesValuesScope != null)
      __params = __params.set(
        'prices_values_scope',
        params.pricesValuesScope.toString()
      );
    if (params.pricesValues != null)
      __params = __params.set('prices_values', params.pricesValues.toString());
    if (params.priceCustomersAccountInfoExtended != null)
      __params = __params.set(
        'price_customers_account_info_extended',
        params.priceCustomersAccountInfoExtended.toString()
      );
    if (params.priceCustomersAccountInfo != null)
      __params = __params.set(
        'price_customers_account_info',
        params.priceCustomersAccountInfo.toString()
      );
    if (params.marginsValues != null)
      __params = __params.set(
        'margins_values',
        params.marginsValues.toString()
      );
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pos/${encodeURIComponent(String(params.posId))}/fuel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PosFuel>;
      })
    );
  }
  /**
   * Return fuel controller of Point of sales (POS)
   * @param params The `POSService.IncustControllersClientClientPosFuelParams` containing the following parameters:
   *
   * - `pos_id`: Point of sales ID
   *
   * - `taxes_values`: Return taxes for price in fuel info (0/1)
   *
   * - `recommended_values_presets`: Return recommended values presets in fuel info (0/1)
   *
   * - `prices_values_scope`: Scope for return available prices (not only base) in fuel info
   *
   * - `prices_values`: Return available prices by scope (not only base) in fuel info (0/1)
   *
   * - `price_customers_account_info_extended`: Include account customers limits data (only if price_customers_account_info is 1)
   *
   * - `price_customers_account_info`: Include account customers data in prices (0/1)
   *
   * - `margins_values`: Return margins for price in fuel info (0/1)
   *
   * @return POS object
   */
  incustControllersClientClientPosFuel(
    params: POSService.IncustControllersClientClientPosFuelParams
  ): __Observable<PosFuel> {
    return this.incustControllersClientClientPosFuelResponse(params).pipe(
      __map((_r) => _r.body as PosFuel)
    );
  }

  /**
   * Return news for Point of sales (POS)
   * @param params The `POSService.IncustControllersClientClientPosNewsParams` containing the following parameters:
   *
   * - `pos_id`: Point of sales ID
   *
   * - `sort_by_priority`: Sort News by priority (0/1)
   *
   * @return News list
   */
  incustControllersClientClientPosNewsResponse(
    params: POSService.IncustControllersClientClientPosNewsParams
  ): __Observable<__StrictHttpResponse<Array<NewsCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.sortByPriority != null)
      __params = __params.set(
        'sort_by_priority',
        params.sortByPriority.toString()
      );
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/pos/${encodeURIComponent(String(params.posId))}/news`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<NewsCategory>>;
      })
    );
  }
  /**
   * Return news for Point of sales (POS)
   * @param params The `POSService.IncustControllersClientClientPosNewsParams` containing the following parameters:
   *
   * - `pos_id`: Point of sales ID
   *
   * - `sort_by_priority`: Sort News by priority (0/1)
   *
   * @return News list
   */
  incustControllersClientClientPosNews(
    params: POSService.IncustControllersClientClientPosNewsParams
  ): __Observable<Array<NewsCategory>> {
    return this.incustControllersClientClientPosNewsResponse(params).pipe(
      __map((_r) => _r.body as Array<NewsCategory>)
    );
  }
}

module POSService {
  /**
   * Parameters for incustControllersClientClientPos
   */
  export interface IncustControllersClientClientPosParams {
    /**
     * Sort POS by priority (0/1)
     */
    sortByPriority?: 0 | 1;

    /**
     * Scope for return referral program info
     */
    referralProgramInfoScope?: 'full' | 'active-status-only';

    /**
     * Return referral program info (0/1)
     */
    referralProgram?: 0 | 1;

    /**
     * Filter by recent (0/1)
     */
    recent?: 0 | 1;

    /**
     * Filter by radius in Km (0 - without filtering)
     */
    radius?: number;

    /**
     * Search string
     */
    q?: string;

    /**
     * Pos type filter if need
     */
    posType?: 'all' | 'offline' | 'pos' | 'road-tanker' | 'online-store';

    /**
     * Scope for return payment systems availability info
     */
    paymentSystemsAvailabilityScope?: 'list' | 'status';

    /**
     * Return payment systems availability info (0/1)
     */
    paymentSystemsAvailability?: 0 | 1;

    /**
     * Filter only with geolocation (0/1)
     */
    onlyWithLocation?: 0 | 1;

    /**
     * Online store order type (only with pos_type eq 'online-store')
     */
    onlineStoreOrderType?: 'online-store' | 'fuel-taxi';

    /**
     * Return online store info (0/1)
     */
    onlineStore?: 0 | 1;

    /**
     * Loyalty ID
     */
    loyalty?: string;

    /**
     * Client longitude
     */
    longitude?: number;

    /**
     * Result rows limit
     */
    limit?: number;

    /**
     * Client latitude
     */
    latitude?: number;

    /**
     * Return taxes for price in fuel info (0/1)
     */
    fuelInfoTaxesValues?: 0 | 1;

    /**
     * Return recommended values presets in fuel info (0/1)
     */
    fuelInfoRecommendedValuesPresets?: 0 | 1;

    /**
     * Scope for return available prices (not only base) in fuel info
     */
    fuelInfoPricesValuesScope?: 'all' | 'applicable' | 'minimal';

    /**
     * Return available prices by scope (not only base) in fuel info (0/1)
     */
    fuelInfoPricesValues?: 0 | 1;

    /**
     * Return margins for price in fuel info (0/1)
     */
    fuelInfoMarginsValues?: 0 | 1;

    /**
     * Return fuel info (0/1)
     */
    fuelInfo?: 0 | 1;

    /**
     * Result start row offset
     */
    fromRowOffset?: number;

    /**
     * Filter by favorite (0/1)
     */
    favorite?: 0 | 1;

    /**
     * Return equipments info (0/1)
     */
    equipmentsInfo?: 0 | 1;

    /**
     * Category ID
     */
    category?: number;

    /**
     * Return additional services info (0/1)
     */
    additionalServices?: 0 | 1;
  }

  /**
   * Parameters for incustControllersClientClientPosDetail
   */
  export interface IncustControllersClientClientPosDetailParams {
    /**
     * Point of sales ID
     */
    posId: string;

    /**
     * Return taxes for price in fuel info (0/1)
     */
    fuelInfoTaxesValues?: 0 | 1;

    /**
     * Return recommended values presets in fuel info (0/1)
     */
    fuelInfoRecommendedValuesPresets?: 0 | 1;

    /**
     * Scope for return available prices (not only base) in fuel info
     */
    fuelInfoPricesValuesScope?: 'all' | 'applicable' | 'minimal';

    /**
     * Return available prices by scope (not only base) in fuel info (0/1)
     */
    fuelInfoPricesValues?: 0 | 1;

    /**
     * Include account customers limits data (only if fuel_info_price_customers_account_info is 1)
     */
    fuelInfoPriceCustomersAccountInfoExtended?: 0 | 1;

    /**
     * Include account customers data in prices (0/1)
     */
    fuelInfoPriceCustomersAccountInfo?: 0 | 1;

    /**
     * Return margins for price in fuel info (0/1)
     */
    fuelInfoMarginsValues?: 0 | 1;

    /**
     * Return fuel info (0/1)
     */
    fuelInfo?: 0 | 1;

    /**
     * Return equipments info (0/1)
     */
    equipmentsInfo?: 0 | 1;

    /**
     * Return coupons info (0/1)
     */
    coupons?: 0 | 1;

    /**
     * Return card categories info (0/1)
     */
    cardCategories?: 0 | 1;

    /**
     * Return bonuses and accounts info (0/1)
     */
    bonuses?: 0 | 1;
  }

  /**
   * Parameters for incustControllersClientClientPosFuel
   */
  export interface IncustControllersClientClientPosFuelParams {
    /**
     * Point of sales ID
     */
    posId: string;

    /**
     * Return taxes for price in fuel info (0/1)
     */
    taxesValues?: 0 | 1;

    /**
     * Return recommended values presets in fuel info (0/1)
     */
    recommendedValuesPresets?: 0 | 1;

    /**
     * Scope for return available prices (not only base) in fuel info
     */
    pricesValuesScope?: 'all' | 'applicable' | 'minimal';

    /**
     * Return available prices by scope (not only base) in fuel info (0/1)
     */
    pricesValues?: 0 | 1;

    /**
     * Include account customers limits data (only if price_customers_account_info is 1)
     */
    priceCustomersAccountInfoExtended?: 0 | 1;

    /**
     * Include account customers data in prices (0/1)
     */
    priceCustomersAccountInfo?: 0 | 1;

    /**
     * Return margins for price in fuel info (0/1)
     */
    marginsValues?: 0 | 1;
  }

  /**
   * Parameters for incustControllersClientClientPosNews
   */
  export interface IncustControllersClientClientPosNewsParams {
    /**
     * Point of sales ID
     */
    posId: string;

    /**
     * Sort News by priority (0/1)
     */
    sortByPriority?: 0 | 1;
  }
}

export { POSService };
