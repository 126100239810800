/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {
  ApiConfiguration,
  ApiConfigurationInterface
} from './api-configuration';

import { SystemService } from './services/system.service';
import { BeaconService } from './services/beacon.service';
import { CardInfoService } from './services/card-info.service';
import { CardsService } from './services/cards.service';
import { ChatbotService } from './services/chatbot.service';
import { CheckService } from './services/check.service';
import { CouponService } from './services/coupon.service';
import { CustomerFeedbackService } from './services/customer-feedback.service';
import { EquipmentService } from './services/equipment.service';
import { FavoriteService } from './services/favorite.service';
import { GiftCardService } from './services/gift-card.service';
import { OnlineStoreService } from './services/online-store.service';
import { JobsService } from './services/jobs.service';
import { LoyaltyService } from './services/loyalty.service';
import { ExternalFormsService } from './services/external-forms.service';
import { MessageService } from './services/message.service';
import { NewsService } from './services/news.service';
import { PurchaseOrderService } from './services/purchase-order.service';
import { PkpassService } from './services/pkpass.service';
import { PaymentService } from './services/payment.service';
import { POSService } from './services/pos.service';
import { PushService } from './services/push.service';
import { QrCodeService } from './services/qr-code.service';
import { GoodsService } from './services/goods.service';
import { TouristCardsService } from './services/tourist-cards.service';
import { TransactionService } from './services/transaction.service';
import { WalletService } from './services/wallet.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  declarations: [],
  providers: [
    ApiConfiguration,
    SystemService,
    BeaconService,
    CardInfoService,
    CardsService,
    ChatbotService,
    CheckService,
    CouponService,
    CustomerFeedbackService,
    EquipmentService,
    FavoriteService,
    GiftCardService,
    OnlineStoreService,
    JobsService,
    LoyaltyService,
    ExternalFormsService,
    MessageService,
    NewsService,
    PurchaseOrderService,
    PkpassService,
    PaymentService,
    POSService,
    PushService,
    QrCodeService,
    GoodsService,
    TouristCardsService,
    TransactionService,
    WalletService
  ]
})
export class ApiModule {
  static forRoot(
    customParams: ApiConfigurationInterface
  ): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: { rootUrl: customParams.rootUrl }
        }
      ]
    };
  }
}
