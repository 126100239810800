/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TourCard } from '../models/tour-card';
@Injectable({
  providedIn: 'root'
})
class TouristCardsService extends __BaseService {
  static readonly incustControllersClientClientTourCardsListPath =
    '/tour_card/cards';
  static readonly incustControllersClientClientTourCardInfoPath =
    '/tour_card/cards/{code}';
  static readonly incustControllersClientClientTourCardAddPath =
    '/tour_card/cards/{id}/add';
  static readonly incustControllersClientClientTourCardDeletePath =
    '/tour_card/cards/{id}/delete';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return tourist cards list
   * @return Tourist card object array
   */
  incustControllersClientClientTourCardsListResponse(): __Observable<
    __StrictHttpResponse<Array<TourCard>>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tour_card/cards`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TourCard>>;
      })
    );
  }
  /**
   * Return tourist cards list
   * @return Tourist card object array
   */
  incustControllersClientClientTourCardsList(): __Observable<Array<TourCard>> {
    return this.incustControllersClientClientTourCardsListResponse().pipe(
      __map((_r) => _r.body as Array<TourCard>)
    );
  }

  /**
   * Return tourist card info
   * @param code Tourist card code
   * @return Tourist card object
   */
  incustControllersClientClientTourCardInfoResponse(
    code: string
  ): __Observable<__StrictHttpResponse<TourCard>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tour_card/cards/${encodeURIComponent(String(code))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TourCard>;
      })
    );
  }
  /**
   * Return tourist card info
   * @param code Tourist card code
   * @return Tourist card object
   */
  incustControllersClientClientTourCardInfo(
    code: string
  ): __Observable<TourCard> {
    return this.incustControllersClientClientTourCardInfoResponse(code).pipe(
      __map((_r) => _r.body as TourCard)
    );
  }

  /**
   * Add tourist card for user and return tourist card info
   * @param id Tourist card ID
   * @return Tourist card object
   */
  incustControllersClientClientTourCardAddResponse(
    id: string
  ): __Observable<__StrictHttpResponse<TourCard>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/tour_card/cards/${encodeURIComponent(String(id))}/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TourCard>;
      })
    );
  }
  /**
   * Add tourist card for user and return tourist card info
   * @param id Tourist card ID
   * @return Tourist card object
   */
  incustControllersClientClientTourCardAdd(id: string): __Observable<TourCard> {
    return this.incustControllersClientClientTourCardAddResponse(id).pipe(
      __map((_r) => _r.body as TourCard)
    );
  }

  /**
   * Delete tourist card for user and return tourist card info
   * @param id Tourist card ID
   * @return Tourist card object
   */
  incustControllersClientClientTourCardDeleteResponse(
    id: string
  ): __Observable<__StrictHttpResponse<TourCard>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/tour_card/cards/${encodeURIComponent(String(id))}/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TourCard>;
      })
    );
  }
  /**
   * Delete tourist card for user and return tourist card info
   * @param id Tourist card ID
   * @return Tourist card object
   */
  incustControllersClientClientTourCardDelete(
    id: string
  ): __Observable<TourCard> {
    return this.incustControllersClientClientTourCardDeleteResponse(id).pipe(
      __map((_r) => _r.body as TourCard)
    );
  }
}

module TouristCardsService {}

export { TouristCardsService };
