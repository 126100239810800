import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { POSService } from '../../api/services';
import { Pos } from '../../api/models/pos';
import { AsyncPipe, CurrencyPipe, NgOptimizedImage } from '@angular/common';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-fuel-prices',
  standalone: true,
  imports: [NgOptimizedImage, CurrencyPipe, AsyncPipe],
  templateUrl: './fuel-prices.component.html',
  styleUrl: './fuel-prices.component.scss'
})
export class FuelPricesComponent implements OnInit {
  protected readonly destroyRef = inject(DestroyRef);
  private posService = inject(POSService);
  private metaService = inject(Meta);
  private titleService = inject(Title);
  private route = inject(ActivatedRoute);
  private destroy$ = new Subject<void>();

  public pos$?: Observable<Pos>;
  public fuelGoods: any = [];

  ngOnInit() {
    this.fetchData();

    this.destroyRef.onDestroy(() => {
      this.destroy$.next();
      this.destroy$.complete();
    });
  }

  fetchData() {
    const posId = this.route.snapshot.paramMap.get('posId');

    if (!posId) {
      return;
    }

    const query: POSService.IncustControllersClientClientPosDetailParams = {
      posId: posId,
      bonuses: 0,
      coupons: 0,
      cardCategories: 0,
      fuelInfo: 1,
      fuelInfoRecommendedValuesPresets: 0,
      fuelInfoTaxesValues: 0,
      fuelInfoMarginsValues: 0,
      equipmentsInfo: 0,
      fuelInfoPricesValuesScope: 'minimal'
    };

    this.pos$ = this.posService
      .incustControllersClientClientPosDetail(query)
      .pipe(
        takeUntil(this.destroy$),
        map((res) => {
          if (res.fuel && res.fuel.pumps) {
            res.fuel.pumps.forEach((pump) => {
              if (pump.nozzles) {
                pump.nozzles.forEach((nozzle) => {
                  if (nozzle.goods) {
                    this.fuelGoods.push(nozzle.goods);
                  }
                });
              }
            });
            this.fuelGoods = this.fuelGoods.filter(
              (obj: { [x: string]: any }, pos: any, arr: any[]) => {
                return (
                  arr.map((mapObj) => mapObj['id']).indexOf(obj['id']) === pos
                );
              }
            );
          }
          console.log('Pos: ', res);
          this.setMetaTags(res);
          return res;
        })
      );
  }

  setMetaTags(data: Pos) {
    const links: any = data.links;
    this.titleService.setTitle(
      data.public_title || data.title || 'Default Title'
    );
    this.metaService.addTags([
      {
        name: 'description',
        content: data.description || 'Default description'
      },
      {
        name: 'keywords',
        content: data.public_title || data.title || 'angular,ssr,example'
      },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: `@${links.web || ''}` },
      { name: 'og:type', content: 'website' },
      {
        name: 'og:site_name',
        content: data.public_title || data.title || 'Default Title'
      },
      {
        name: 'og:title',
        content: data.public_title || data.title || 'Default Title'
      },
      {
        name: 'og:url',
        content: `${environment.CLIENT_URL}/pos/${data.id}/fuel`
      },
      {
        name: 'og:description',
        content: data.description || 'Default description'
      },
      {
        name: 'og:image',
        content: (data.photos && data.photos[0] && data.photos[0].url) || ''
      },
      { name: 'og:image:width', content: '600' },
      { name: 'og:image:height', content: '315' }
    ]);
  }
}
