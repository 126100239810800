/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { EquipmentRec } from '../models/equipment-rec';
@Injectable({
  providedIn: 'root'
})
class EquipmentService extends __BaseService {
  static readonly incustControllersClientClientEquipmentInfoPath =
    '/equipment/{equipment_id}/info';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Returns equipment data
   * @param params The `EquipmentService.IncustControllersClientClientEquipmentInfoParams` containing the following parameters:
   *
   * - `equipment_id`: Equipment ID
   *
   * - `coupons`: Return coupons info (0/1)
   *
   * - `card_categories`: Return card categories info (0/1)
   *
   * - `bonuses`: Return bonuses and accounts info (0/1)
   *
   * @return Equipment data
   */
  incustControllersClientClientEquipmentInfoResponse(
    params: EquipmentService.IncustControllersClientClientEquipmentInfoParams
  ): __Observable<__StrictHttpResponse<EquipmentRec>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.coupons != null)
      __params = __params.set('coupons', params.coupons.toString());
    if (params.cardCategories != null)
      __params = __params.set(
        'card_categories',
        params.cardCategories.toString()
      );
    if (params.bonuses != null)
      __params = __params.set('bonuses', params.bonuses.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/equipment/${encodeURIComponent(String(params.equipmentId))}/info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<EquipmentRec>;
      })
    );
  }
  /**
   * Returns equipment data
   * @param params The `EquipmentService.IncustControllersClientClientEquipmentInfoParams` containing the following parameters:
   *
   * - `equipment_id`: Equipment ID
   *
   * - `coupons`: Return coupons info (0/1)
   *
   * - `card_categories`: Return card categories info (0/1)
   *
   * - `bonuses`: Return bonuses and accounts info (0/1)
   *
   * @return Equipment data
   */
  incustControllersClientClientEquipmentInfo(
    params: EquipmentService.IncustControllersClientClientEquipmentInfoParams
  ): __Observable<EquipmentRec> {
    return this.incustControllersClientClientEquipmentInfoResponse(params).pipe(
      __map((_r) => _r.body as EquipmentRec)
    );
  }
}

module EquipmentService {
  /**
   * Parameters for incustControllersClientClientEquipmentInfo
   */
  export interface IncustControllersClientClientEquipmentInfoParams {
    /**
     * Equipment ID
     */
    equipmentId: string;

    /**
     * Return coupons info (0/1)
     */
    coupons?: 0 | 1;

    /**
     * Return card categories info (0/1)
     */
    cardCategories?: 0 | 1;

    /**
     * Return bonuses and accounts info (0/1)
     */
    bonuses?: 0 | 1;
  }
}

export { EquipmentService };
