/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { News } from '../models/news';
@Injectable({
  providedIn: 'root'
})
class NewsService extends __BaseService {
  static readonly incustControllersClientClientNewsListPath = '/news';
  static readonly incustControllersClientClientNewsPath = '/news/{news_id}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return news list
   * @param params The `NewsService.IncustControllersClientClientNewsListParams` containing the following parameters:
   *
   * - `sort_by_priority`: Sort News by priority (0/1)
   *
   * - `longitude`:
   *
   * - `limit`: Result rows limit
   *
   * - `latitude`:
   *
   * - `from_row_offset`: Result start row offset
   *
   * @return News objects list
   */
  incustControllersClientClientNewsListResponse(
    params: NewsService.IncustControllersClientClientNewsListParams
  ): __Observable<__StrictHttpResponse<Array<News>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.sortByPriority != null)
      __params = __params.set(
        'sort_by_priority',
        params.sortByPriority.toString()
      );
    if (params.longitude != null)
      __params = __params.set('longitude', params.longitude.toString());
    if (params.limit != null)
      __params = __params.set('limit', params.limit.toString());
    if (params.latitude != null)
      __params = __params.set('latitude', params.latitude.toString());
    if (params.fromRowOffset != null)
      __params = __params.set(
        'from_row_offset',
        params.fromRowOffset.toString()
      );
    let req = new HttpRequest<any>('GET', this.rootUrl + `/news`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<News>>;
      })
    );
  }
  /**
   * Return news list
   * @param params The `NewsService.IncustControllersClientClientNewsListParams` containing the following parameters:
   *
   * - `sort_by_priority`: Sort News by priority (0/1)
   *
   * - `longitude`:
   *
   * - `limit`: Result rows limit
   *
   * - `latitude`:
   *
   * - `from_row_offset`: Result start row offset
   *
   * @return News objects list
   */
  incustControllersClientClientNewsList(
    params: NewsService.IncustControllersClientClientNewsListParams
  ): __Observable<Array<News>> {
    return this.incustControllersClientClientNewsListResponse(params).pipe(
      __map((_r) => _r.body as Array<News>)
    );
  }

  /**
   * Return news by id
   * @param news_id News ID
   * @return News object
   */
  incustControllersClientClientNewsResponse(
    newsId: string
  ): __Observable<__StrictHttpResponse<News>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/news/${encodeURIComponent(String(newsId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<News>;
      })
    );
  }
  /**
   * Return news by id
   * @param news_id News ID
   * @return News object
   */
  incustControllersClientClientNews(newsId: string): __Observable<News> {
    return this.incustControllersClientClientNewsResponse(newsId).pipe(
      __map((_r) => _r.body as News)
    );
  }
}

module NewsService {
  /**
   * Parameters for incustControllersClientClientNewsList
   */
  export interface IncustControllersClientClientNewsListParams {
    /**
     * Sort News by priority (0/1)
     */
    sortByPriority?: 0 | 1;
    longitude?: number;

    /**
     * Result rows limit
     */
    limit?: number;
    latitude?: number;

    /**
     * Result start row offset
     */
    fromRowOffset?: number;
  }
}

export { NewsService };
