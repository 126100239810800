/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { MessageResponse } from '../models/message-response';
import { ChatbotTokenData } from '../models/chatbot-token-data';
@Injectable({
  providedIn: 'root'
})
class ChatbotService extends __BaseService {
  static readonly incustControllersClientClientChatbotTokenDelPath =
    '/chatbot_token';
  static readonly incustControllersClientClientChatbotTokenSetPath =
    '/chatbot_token';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Delete chatbot token
   * @param params The `ChatbotService.IncustControllersClientClientChatbotTokenDelParams` containing the following parameters:
   *
   * - `token`: Chatbot token
   *
   * - `channel`: Type of token channel
   *
   * @return Ok
   */
  incustControllersClientClientChatbotTokenDelResponse(
    params: ChatbotService.IncustControllersClientClientChatbotTokenDelParams
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null)
      __params = __params.set('token', params.token.toString());
    if (params.channel != null)
      __params = __params.set('channel', params.channel.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/chatbot_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Delete chatbot token
   * @param params The `ChatbotService.IncustControllersClientClientChatbotTokenDelParams` containing the following parameters:
   *
   * - `token`: Chatbot token
   *
   * - `channel`: Type of token channel
   *
   * @return Ok
   */
  incustControllersClientClientChatbotTokenDel(
    params: ChatbotService.IncustControllersClientClientChatbotTokenDelParams
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientChatbotTokenDelResponse(
      params
    ).pipe(__map((_r) => _r.body as MessageResponse));
  }

  /**
   * Register new chatbot token
   * @param data Chatbot token data
   * @return Ok
   */
  incustControllersClientClientChatbotTokenSetResponse(
    data: ChatbotTokenData
  ): __Observable<__StrictHttpResponse<MessageResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/chatbot_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MessageResponse>;
      })
    );
  }
  /**
   * Register new chatbot token
   * @param data Chatbot token data
   * @return Ok
   */
  incustControllersClientClientChatbotTokenSet(
    data: ChatbotTokenData
  ): __Observable<MessageResponse> {
    return this.incustControllersClientClientChatbotTokenSetResponse(data).pipe(
      __map((_r) => _r.body as MessageResponse)
    );
  }
}

module ChatbotService {
  /**
   * Parameters for incustControllersClientClientChatbotTokenDel
   */
  export interface IncustControllersClientClientChatbotTokenDelParams {
    /**
     * Chatbot token
     */
    token: string;

    /**
     * Type of token channel
     */
    channel: 'facebook' | 'telegram' | 'viber' | 'wawebproxy' | 'wabcloud';
  }
}

export { ChatbotService };
