/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CustomerWallet } from '../models/customer-wallet';
@Injectable({
  providedIn: 'root'
})
class WalletService extends __BaseService {
  static readonly incustControllersClientClientWalletPath = '/wallet';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Client wallet
   * @param group_by_business Group wallet objects by Businesses (default grouping by Loyalties)
   * @return Wallet data
   */
  incustControllersClientClientWalletResponse(
    groupByBusiness?: boolean
  ): __Observable<__StrictHttpResponse<CustomerWallet>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (groupByBusiness != null)
      __params = __params.set('group_by_business', groupByBusiness.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/wallet`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json'
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CustomerWallet>;
      })
    );
  }
  /**
   * Client wallet
   * @param group_by_business Group wallet objects by Businesses (default grouping by Loyalties)
   * @return Wallet data
   */
  incustControllersClientClientWallet(
    groupByBusiness?: boolean
  ): __Observable<CustomerWallet> {
    return this.incustControllersClientClientWalletResponse(
      groupByBusiness
    ).pipe(__map((_r) => _r.body as CustomerWallet));
  }
}

module WalletService {}

export { WalletService };
