/* tslint:disable */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpRequest,
  HttpResponse,
  HttpHeaders
} from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Goods } from '../models/goods';
@Injectable({
  providedIn: 'root'
})
class GoodsService extends __BaseService {
  static readonly incustControllersClientClientGetGoodsForTerminalPath =
    '/terminal/{terminal_id}/goods';
  static readonly incustControllersClientClientGetGoodsItemPath =
    '/terminal/{terminal_id}/goods/{code}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Return goods item for terminal
   * @param params The `GoodsService.IncustControllersClientClientGetGoodsForTerminalParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `service_type`: Return goods with requested service types. By default return goods without service type.
   *
   * - `price_customers_account_info_extended`: Include account customers limits data (only if price_customers_account_info is true)
   *
   * - `price_customers_account_info`: Include account customers data in prices
   *
   * - `online_store_available_only`: Return only online-store available goods.
   *
   * - `fuel_info`: Return fuel info
   *
   * @return Goods data
   */
  incustControllersClientClientGetGoodsForTerminalResponse(
    params: GoodsService.IncustControllersClientClientGetGoodsForTerminalParams
  ): __Observable<__StrictHttpResponse<Array<Goods>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.serviceType != null)
      __params = __params.set('service_type', params.serviceType.toString());
    if (params.priceCustomersAccountInfoExtended != null)
      __params = __params.set(
        'price_customers_account_info_extended',
        params.priceCustomersAccountInfoExtended.toString()
      );
    if (params.priceCustomersAccountInfo != null)
      __params = __params.set(
        'price_customers_account_info',
        params.priceCustomersAccountInfo.toString()
      );
    if (params.onlineStoreAvailableOnly != null)
      __params = __params.set(
        'online_store_available_only',
        params.onlineStoreAvailableOnly.toString()
      );
    if (params.fuelInfo != null)
      __params = __params.set('fuel_info', params.fuelInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/terminal/${encodeURIComponent(String(params.terminalId))}/goods`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Goods>>;
      })
    );
  }
  /**
   * Return goods item for terminal
   * @param params The `GoodsService.IncustControllersClientClientGetGoodsForTerminalParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `service_type`: Return goods with requested service types. By default return goods without service type.
   *
   * - `price_customers_account_info_extended`: Include account customers limits data (only if price_customers_account_info is true)
   *
   * - `price_customers_account_info`: Include account customers data in prices
   *
   * - `online_store_available_only`: Return only online-store available goods.
   *
   * - `fuel_info`: Return fuel info
   *
   * @return Goods data
   */
  incustControllersClientClientGetGoodsForTerminal(
    params: GoodsService.IncustControllersClientClientGetGoodsForTerminalParams
  ): __Observable<Array<Goods>> {
    return this.incustControllersClientClientGetGoodsForTerminalResponse(
      params
    ).pipe(__map((_r) => _r.body as Array<Goods>));
  }

  /**
   * Return goods item
   * @param params The `GoodsService.IncustControllersClientClientGetGoodsItemParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `code`: Good code
   *
   * - `price_customers_account_info_extended`: Include account customers limits data (only if price_customers_account_info is true)
   *
   * - `price_customers_account_info`: Include account customers data in prices
   *
   * - `fuel_info`: Return fuel info
   *
   * @return Goods data
   */
  incustControllersClientClientGetGoodsItemResponse(
    params: GoodsService.IncustControllersClientClientGetGoodsItemParams
  ): __Observable<__StrictHttpResponse<Goods>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.priceCustomersAccountInfoExtended != null)
      __params = __params.set(
        'price_customers_account_info_extended',
        params.priceCustomersAccountInfoExtended.toString()
      );
    if (params.priceCustomersAccountInfo != null)
      __params = __params.set(
        'price_customers_account_info',
        params.priceCustomersAccountInfo.toString()
      );
    if (params.fuelInfo != null)
      __params = __params.set('fuel_info', params.fuelInfo.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/terminal/${encodeURIComponent(
          String(params.terminalId)
        )}/goods/${encodeURIComponent(String(params.code))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      }
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Goods>;
      })
    );
  }
  /**
   * Return goods item
   * @param params The `GoodsService.IncustControllersClientClientGetGoodsItemParams` containing the following parameters:
   *
   * - `terminal_id`: Terminal ID
   *
   * - `code`: Good code
   *
   * - `price_customers_account_info_extended`: Include account customers limits data (only if price_customers_account_info is true)
   *
   * - `price_customers_account_info`: Include account customers data in prices
   *
   * - `fuel_info`: Return fuel info
   *
   * @return Goods data
   */
  incustControllersClientClientGetGoodsItem(
    params: GoodsService.IncustControllersClientClientGetGoodsItemParams
  ): __Observable<Goods> {
    return this.incustControllersClientClientGetGoodsItemResponse(params).pipe(
      __map((_r) => _r.body as Goods)
    );
  }
}

module GoodsService {
  /**
   * Parameters for incustControllersClientClientGetGoodsForTerminal
   */
  export interface IncustControllersClientClientGetGoodsForTerminalParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Return goods with requested service types. By default return goods without service type.
     */
    serviceType?: 'none' | 'all' | 'fuel';

    /**
     * Include account customers limits data (only if price_customers_account_info is true)
     */
    priceCustomersAccountInfoExtended?: boolean;

    /**
     * Include account customers data in prices
     */
    priceCustomersAccountInfo?: boolean;

    /**
     * Return only online-store available goods.
     */
    onlineStoreAvailableOnly?: boolean;

    /**
     * Return fuel info
     */
    fuelInfo?: boolean;
  }

  /**
   * Parameters for incustControllersClientClientGetGoodsItem
   */
  export interface IncustControllersClientClientGetGoodsItemParams {
    /**
     * Terminal ID
     */
    terminalId: string;

    /**
     * Good code
     */
    code: string;

    /**
     * Include account customers limits data (only if price_customers_account_info is true)
     */
    priceCustomersAccountInfoExtended?: boolean;

    /**
     * Include account customers data in prices
     */
    priceCustomersAccountInfo?: boolean;

    /**
     * Return fuel info
     */
    fuelInfo?: boolean;
  }
}

export { GoodsService };
