@if (pos$ | async; as pos) {
    <div class="container">
        @if (pos.photos && pos.photos[0]) {
            <div class="logo-holder">
                <img src="{{pos.photos[0].url || pos.photos[0]}}" alt="">
            </div>
        }

        <h3>{{ pos.title }}</h3>

        @if (pos.equipments && pos.equipments.length > 0) {
            <div class="cards-holder">
                @for (equipmentRec of pos.equipments; track equipmentRec.id) {
                    <div class="card">
                        <strong class="title">{{ equipmentRec.title }}</strong>
                        <div class="card-info">
                            <img src="{{ pos.photos && pos.photos[0] ? pos.photos[0].url : 'dummy_pos.png'}}" alt="">
                            <div class="price">
                                 <span>{{ 'app#equipment#eq-status' }}
                                     : {{ ('app#equipment#eq-status#' + equipmentRec.controller_status) }}</span>
                                <span class="status-controller"
                                      [ngClass]="{'available': equipmentRec.controller_status === 'available'}"></span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        } @else {
            <div class="no-data">
                <span> No data available </span>
            </div>
        }
    </div>
} @else {
    <div class="no-data">
        <span> Pos not available </span>
    </div>
}

